.aboutbuilding{
    font-size: 32px;
    font-family: 'Inter', sans-serif ;
    width: 100%;
    background: rgb(36,176,187);
    background: linear-gradient(106deg, rgba(36,176,187,1) 9%, rgba(36,148,187,1) 23%, rgba(36,186,187,1) 47%, rgba(36,140,187,1) 66%, rgba(36,187,180,1) 93%, rgba(36,140,187,1) 100%, rgba(148,22,192,1) 100%);
     display: flex;
     flex-direction: column;
     align-items: center;justify-content: center;
   // 
     padding-top: 8rem;
     padding-bottom: 8rem;
   
}


.building{
    padding: 0.5rem;
    line-height: 36px;
    font-weight: 600;
    color : #ffffff;
    text-align: center;
    display: flex;
 

}
.marketplace{
    padding:0.5rem;
        font-size: 50px;
        letter-spacing: 0.01em;
        font-weight: 800  !important;
       font-family: 'Inter' , sans-serif ;
        color: #125465;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

}


@media (max-width: 768px) and (min-width: 320px) {


    .aboutbuilding{

        padding-top: 3rem;
        padding-bottom: 3rem;
   }
   
   
   .building{
     
       font-size: 20px; 
       line-height: 36px;
       font-weight: 600;
       color : #ffffff;
       text-align: center;
       display: flex;

 
       align-items: center;
   
   }
   .marketplace{
      
           font-size: 20px;
           letter-spacing: 0.01em;
           line-height: 140%;
           text-transform: capitalize;
           font-weight: 800  !important;
       
          font-family: 'Inter' , sans-serif ;
           color: #125465;
           text-align: center;     
           display: flex;
        

 } }