.sdp_overview_container{
    padding: 3rem;
    max-width: 100vw;
}
.sdpshopOverviewParent {
 
padding: 1rem;
    border-radius: 16px;
    background-color:#f6f8fb;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    font-size: 22px;
    color: #1a212b;
    font-family: "Inter" , sans-serif ;
}
.sdpshopOverview {
    line-height: 24px;
    font-weight: 600;  
}
.sdpspaciousAndBright{
color: #728197;
font-size: 1rem;
}



@media (max-width: 768px) and (min-width: 320px)  {

    .sdp_overview_container{
        padding: 1.5rem;
    }
    .sdpspaciousAndBright{
        color: #728197;
        font-size: 0.8rem;
        }
        
    .sdpshopOverviewParent {
        border-radius: 16px;
        background-color:#f6f8fb;
        display: flex;
        padding: 1rem;
        flex-direction: column;
        box-sizing: border-box;
        gap: 10px;
        text-align: left;
        font-size: 22px;
        color: #1a212b;
        font-family: "Inter" , sans-serif ;
    }
    

}