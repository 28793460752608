.user_form_container_navbar {
  font-family: "Inter", sans-serif;
  padding: 15rem;
  display: flex;
  flex-direction: column;
 
  padding-top: 5rem;
  
//  width: 100%; /* You may want to specify a max-width for better centering */

}

@media (max-width: 150px) {
  .user_form_container_navbar {
      padding: 10rem;
      padding-bottom: 10rem;
  }
}
@media (max-width: 900px) {
  .user_form_container_navbar {
      padding: 7rem;
      padding-bottom: 10rem;
  }
}

@media (max-width: 800px) {
  .user_form_container_navbar {
      padding: 6rem;
      padding-bottom: 10rem;
  }
}

/* For screens 600px and below */
@media (max-width: 600px) {
  .user_form_container_navbar {
      padding: 3rem;
      padding-bottom: 8rem;
  }
}

/* For screens 400px and below */
@media (max-width: 400px) {
  .user_form_container {
      padding: 2rem;
      padding-bottom: 8rem;
  }
}


.logo_img_popup{
  display: flex;
 
}
.logo_img_popup_img{
  width: 150px;
  height: 30px ;
}
.user_form_section{
  display: flex;
  flex-direction: column;
  gap : 2rem ;
 
}
.user_name_email_collection ,.user_location_about_collection{
  display: flex;

  flex-direction: row;
  flex: 2;
  gap: 1rem;
  justify-content: space-between;
 

}
.Sign_up_for_early_access{
  color: rgb(22, 28, 35);
  font-size: 2rem;
  font-weight: 600;
}
.headline_user_details{
  color: rgb(111, 111, 111);
  font-size: 1rem;
}
.additional_section{
  display: flex;
  flex-direction: column;

}
.user_input ,.user_input_location {
  height: 2rem;
  width: 99%;
  border-radius: 5px;
  border: 1px solid #cbd4e1;
  transition: all 0.4s ease-in-out;

&:focus {
outline: none;
  border: 1px solid #09a7b3;
 
}
&:hover {
  border: 1px solid #09a7b3;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
}


.user_input_role{
  height: 2.3rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #cbd4e1;
  transition: all 0.4s ease-in-out;
&:focus {
outline: none;
  border: 1px solid #09a7b3;
}
&:hover {
  border: 1px solid #09a7b3;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
}


.user_input_additional{
  height: 6rem ;
  border-radius: 5px;
  padding: 0.3rem;
  border: 1px solid #cbd4e1;
  overflow-x: auto;         /* Vertical scrollbar when content exceeds textarea height */
  resize: vertical;         /* Allows vertical resizing */
  transition: all 0.4s ease-in-out;
&:focus {
outline: none;
  border: 1px solid #09a7b3;
}
&:hover {
  border: 1px solid #09a7b3;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
}
.tag_line_user ,.location_user ,.role_of_user,.additional_details{
  color: rgb(22, 28, 35);
  font-size: 0.9rem;
}
.user_input_section{
  box-sizing: border-box;
 width: 100% !important;
 display: flex;
  flex-direction: column;
  gap :1.5rem ;

}
.category_select{
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  &:focus {
    outline: none;
      border: 1px solid #09a7b3;
      border-radius: 10px;
     
    }
    &:hover {
     // border: 1px solid #09a7b3;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    }
}


.submit_button{
   padding: 0.7rem;
    border-radius: 5px;
    background: #09a7b3;
    text-align: center;
    color: white;
    align-items: center;
    cursor: pointer;
    font-size: 1.2rem;
   border: 1px solid #09a7b3;
   transition: all 0.4s ease-in-out;


   &:hover {
    // border: 1px solid #09a7b3;
    transform: scale(1.005);
     box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
   }
}
.user_name_collection ,.email_collection ,.location_collection ,.role_of_request{ 
  flex: 1;
  width: 100%;
}

 /* Placeholder text color */
.user_input_additional::placeholder {
  font-size: 14px;
  font-family: "Inter" ,sans-serif;
  padding: 0.1rem;
  color: #aaa;             
}




@media (max-width: 768px) and (min-width: 320px) {

  .user_form_container_navbar{
    font-family: "Inter" ,sans-serif;
    padding: 2rem;
    padding-bottom: 10rem;
    width: 100%;
    box-sizing: border-box;
    display: flex ;
  
}

 }