.optimisesection{
 font-family:  "Inter","sans-serif";
    padding : 4rem ;
   margin-top: 4rem;
}

.optimise_background {
    position: relative; /* Set relative positioning for the container */
    text-align: center; /* Center text horizontally */
}

.optimise_background_img {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove bottom space under the image */
}
.optimise_text{
    text-align: left;
}
.optimise_text_button {
display: flex;
flex-direction: column;
gap : 2rem  ;
    position: absolute; /* Position absolutely within the relative container */
    top: 42%; /* Center vertically */
    left: 28%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust positioning */
    color: white; /* Set text color for visibility */
    text-align: left; /* Center text */
}

.list_with_us_button_link {
    display: inline-block; /* Make the button inline */
    padding: 10px 20px; /* Add padding for button */
    background-color: #000000; /* Button background color */
    color: rgb(255, 255, 255); /* Button text color */
    text-decoration: none; /* Remove underline */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.4s; /* Smooth transition for hover */
    transition: all 0.4s ease-in-out; /* Smooth transition for hover */
}

.list_with_us_button_link:hover {
    background-color: #0071b7; /* Darken button on hover */
    color: white;
    transform: scale(1.1);
}


.list_with_us_button_link{
    text-decoration: none;
}
.optimise_headline{
    font-size: 1.2rem;
}
.optimise_desc{
    max-width: 500px;
    font-size: 2.4rem;
    font-weight: 500;

}

.about_optimise_content_img{
    position: absolute; /* Position absolutely within the relative container */
    top: 60%; /* Center vertically */
    left: 70%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust positioning */
}

@media (max-width: 768px) and (min-width: 320px) { 
    .optimisesection{
 
        padding : 1rem ;
    }
    .optimise_headline{
        font-size: 0.5rem;
    }
    .optimise_desc{
        max-width: 130px;
        font-size: 0.8rem;
        font-weight: 500;
    
    }
    .optimise_text_button {
        display: flex;
        flex-direction: column;
        gap : 0.5rem  ;
            position: absolute; /* Position absolutely within the relative container */
            top: 50%; /* Center vertically */
            left: 25%; /* Center horizontally */
            transform: translate(-50%, -50%); /* Adjust positioning */
            color: white; /* Set text color for visibility */
            text-align: left; /* Center text */
        }
        
.list_with_us_button_link {
    font-size: 0.6rem;
    display: inline-block; /* Make the button inline */
    padding: 5px 8px; /* Add padding for button */
    background-color: #000000; /* Button background color */
    color: rgb(255, 255, 255); /* Button text color */
    text-decoration: none; /* Remove underline */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth transition for hover */
}

.list_with_us_button_link:hover {
    background-color: #0071b7; /* Darken button on hover */
    color: white;
}
.optimise_side_img_1{
    width: 150px;
    height: 150px;
}
}
