.loginpage {
    font-family: "Inter",sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
}


.loginoptionsfull{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5rem;
    gap : 3rem ;
    flex-direction: column;
}

.loginoption2{
    border : 1px  solid #ccc ;
     border-radius: 8px;
   
     padding: 1rem;
cursor: pointer;
}
.loginoption2:hover{
    border : 1px  solid #09a7b3 ;
}
    .loginoption3{
        border : 1px  solid #ccc ;
        padding: 1rem;
        border-radius: 8px;
     
        
        background-color: #09a7b3;
       cursor: pointer;
    }
    .loginoption3:hover{
        border : 1px  solid #09a7b3 ;
       
}

.loginoption1data{
    color : rgb(166, 161, 161)
}
.loginoption1data3{
    color : rgb(18, 17, 17)
}
.loginoption1head3{
    color: rgb(255, 255, 255);

}
.loginoption1head{
    color: rgb(0, 0, 0);

}
.loginoptions{
    display: flex;
 
    flex-direction: column;

    gap : 15px ;    
}

.loginheadline{
    text-align: center;
    font-size: 30px;
    font-weight: 500;
}

@media (max-width: 768px) and (min-width: 320px) {
    .loginheadline{
        text-align: center;
        font-size: 25px;
        font-weight: 500;
    }

    .loginoptionsfull{
        display: flex;
        padding: 1rem;
        padding-top: 2rem;
        flex-direction: column;
    }


  .loginoption1data3{
   
  }
  .loginoption1data{
    
  }
  .loginoptions{
   
  }
  .loginoption1head{
  
  }
  .loginoption1head3{
   
}
.loginheadline{
}
.loginpage {
    font-family: "Inter",sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}
.loginoption2{
   
   
}
.loginoption3{
   
}
 }
