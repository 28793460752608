.contract-details{
    font-family: "Inter",sans-serif;
}
.contract_details_container{
    display: flex;
    align-items: center;
 text-align: left;
    padding: 9rem;
    padding-top: 5rem;

}
.third_retail_contract_logo{
    width: 60px ;
    height: 60px;
 }

 .billing_agreement {
    display: flex; /* Enables Flexbox for the container */
    justify-content: space-between; /* Adds space between the columns */
    align-items: flex-start; /* Aligns items to the top */
     gap: 20px; /* Optional: Adds space between the columns */
  //  padding: 20px; /* Optional: Adds some padding around the container */
  }
  
  .agreement_sign_customer,
  .agreement_sign_company {
    
 
  }
.company_logo_signed_contract{
    width: 20px;
    height: 20px;
}
.logo_company_name_signed{
    display: flex;
    text-align: center;
    gap : 0.5rem  ;
    justify-content: center;
}
// accept button 

.contract_accept_reject_section{
 margin-top: 3rem;
}


.accept_contract {
  text-align: center;
  border: 1px solid #09a7b3;
  padding: 10px;
  border-radius: 8px;
  width: 100px !important;
 // transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  transition: all 0.4s ease;
  background: #09a7b3;
  color: white;
  position: relative; /* To position text and image absolutely within */
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.accepted_text_img{

  display: flex;
  text-align: center;
  justify-content: center;
  gap : 0.3rem ;
  animation: slide-in 1s forwards; /* Animation to slide the content in */



}
.accepted_image{
  align-items: center;      /* Center image vertically */
  justify-content: center;  /* Center image horizontally (in case of varying image sizes) */
  width: 25px ;

  height: 25px;
}
.accepted_img,
.accepted_text {
  opacity: 0;
  align-items: center;
  justify-content: center;
  transform: translateX(-100%);
  animation: fade-slide-in 1s forwards; /* Animation to slide and fade in */
  animation-delay: 0.2s; /* Start animation slightly after parent */
}
.accepted_text{
  padding-top: 2px;
}
.accept_contract.accepted{
  padding-top: 8px;
  padding-bottom: 3px;
  background: #0fa726;
  transform: scale(1.1)
}
// key frame
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fade-slide-in {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) and (min-width: 320px) {
    .contract_details_container{
        display: flex;
        align-items: center;
     text-align: left;
        padding: 2rem;
        font-size: 0.8rem;
        padding-top: 3rem;
    
    }
    .billing_agreement{
        font-size: 0.8rem;
      }
    .third_retail_contract_logo{
        width: 40px ;
        height: 40px;
     }
     .accept_contract {
      padding: 10px 25px;
      font-size: 1.1rem;
    }
    .accept_contract.accepted{
      padding-top: 10px;
      width: 80px !important;
      padding-bottom: 5px !important;
      background: #0fa726;
      transform: scale(1.1)
    }
}


.customer_information_grid {
    display: grid; /* Default to grid layout for larger screens */
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    gap: 20px; /* Adds space between grid items */
    margin: 20px 0; /* Adds margin around the grid */
    padding: 10px; /* Optional: Adds padding around the grid */
  }
  
  .customer_information_grid label {
    display: flex;
    flex-direction: column; /* Aligns label and input vertically */
    font-weight: 500; /* Optional: Makes the label text slightly bold */
  }
  
  .customer_information_grid input {
    padding: 8px; /* Adds padding inside input fields */
    margin-top: 5px; /* Adds space between the label and input */
    border: 1px solid #ccc; /* Adds a border around input fields */
    border-radius: 4px; /* Rounds corners of input fields */
    font-size: 14px; /* Sets the font size */
    width: 100%; /* Ensures inputs take the full width */
    box-sizing: border-box; /* Ensures padding does not affect total width */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .customer_information_grid {
      display: flex; /* Switch to flex layout */
      flex-direction: column; /* Stack items in a single column */
      gap: 10px; /* Adjust space between items */
    }
  }
  