.login_mainpage{
  display: flex;
  justify-content: space-between;
  font-family: "Inter" ,sans-serif;
  flex : 10;
  height: 100vh;
overflow: hidden;
  
}
.logsidePage1{
  flex: 5 ;
  height: 100vh;
  overflow: hidden;  
}
.login_sidebar{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  display: block; /* Remove the inline element gap */

}
#roleinput:focus {
  outline: none; /* Remove default focus outline */
  border: 2px solid #9AA8BC; /* Ensure the border style and color change */
}
#roleinput::placeholder {
  color : #728297 ;
  font-size: 0.8rem; /* Change placeholder font size */
 
}
.loggroupParent{
  display: flex;
  flex-direction: column;
  gap : 2rem ;
  flex: 5 ;
  padding: 4rem;

}
.eye-icon2
{
 
    position: absolute;
    right: 10px; /* Position the icon inside the input field */
    cursor: pointer;
    width: 20px; /* Adjust the size as needed */
    height: 20px;
  
}
.input-wrapper  {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; /* Adjust as per your layout */
}



.eye-icon1 {
  position: absolute;
  right: 10px; /* Position the icon inside the input field */
  cursor: pointer;
  width: 20px; /* Adjust the size as needed */
  height: 20px;
}


.loglogoIcon{
  width: 7.5rem;
  height:1.5rem;
}

.logloginYourAccount1 {
  color: #001325;
  font-size: 1.8rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Adding shadow around the text */

}
.newtoretail {
  color : #728297 ;
  font-size: 0.8rem;
}
.logforgetYourPassword1{
  color : #728297 ;
  font-size: 0.8rem;
  text-decoration: underline;
}
.logcreateYourAccount{
  font-size: 0.8rem;
  color: black;
  text-decoration: underline;
}
.logloginYourAccountParent{
  display: flex;
  flex-direction: column;
 gap : 1rem ;
}
.Authheadlinefield {
  font-size: 0.8rem ;
  color : #728297 ;
}
.agreementoncreateaccount{
  width: 100%;
  font-size: 0.8rem ;
  color : #728297 ;
}
.loginformauth{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap : 1rem ;

}
.loginformauth_create_profile{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap : 0.5rem ;
}
#loginput_email , #loginput2_password , #create_account_lastname , #create_account_firstname{
  display: flex;
  width: 100%;
   padding: 12px;
  border: 1px solid #9AA8BC;
  border-radius: 8px;

}
.loginput09{
  width: 100%;
}
.log_in_forget_button{
  width: 100%;
}
.signinbutton ,.signupbutton{
 
 display: flex;
 text-align: center;
 align-items: center;
 justify-content: center;
 color: white;
  border-radius: 10px;
  background-color: #09A7B3;
  cursor: pointer;
  padding: 12px;

}
.loginputname{
  display: flex;
  flex-direction: row;
  gap : 1rem ;
}
.auth_logo_input_field{
  display: flex;
  flex-direction: column;
  gap : 2rem ;
}

.checkboxandpolicy{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.password-requirements{
  position: absolute;
  border : 1px solid #9AA8BC;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 15%;
  margin-left: 20%;
  transition: width 0.5s ease-in-out;
  transform-origin: center; /* Ensures the division opens from the center */
background: white;

}

@media (max-width: 768px) and (min-width: 320px) {
  .password-requirements{
    position: absolute;
    border : 1px solid #9AA8BC;
    border-radius: 5px;
    padding: 0.5rem;
align-items: center;
margin: 10%;
    margin-left: 10%;
    margin-top: 20%;
    transition: width 0.5s ease-in-out;
    transform-origin: center; /* Ensures the division opens from the center */
  background: white;
  
  }
  
.checkboxandpolicy
{
  margin-top: 2rem;
}

 .logsidePage1{
  flex: 0 ;
  height: 100vh;
  display: none;
  overflow: hidden;  
}
.loggroupParent{
  display: flex;
  flex-direction: column;
  gap : 2rem ;
  flex: 10 ;
  padding: 2rem;
  padding-top: 5rem;

}
 .login_mainpage{
  display: flex;
  flex: 10;
 }
 .loginputname{
  display: flex;
  flex-direction: row;
  gap : 0.5rem ;
}

}