
.solve-container{
    background-color: #f6f8fb;
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
    padding-right: 4rem;
   
}

.solve-title{
    margin: 2em 0;
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    text-align: center;
}

.solve-body p{
    margin: 0;
    padding: 0;
}

.solve-text-highlight{
    color: #09a7b3;
}

.solve-sub-title{
    font-size: 0.85rem;
    color: #728197;
    font-weight: 400;
    margin: 1em auto;
    max-width: 60%;
    padding: 0.5em;
}


.problems {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 7em;
}


.solve-card{
    border: 1px solid #cbd4e1;
    border-radius: 20px;
    margin: 2em 1em;
    padding: 1em;
    background-color: white ;
    align-items:start;
    text-align: center;
    cursor: pointer;
    flex: 1;
    flex-direction: column;
    margin: 0.5em;
    display: flex;
    height: 260px; /* Set your desired height */
}

.solve-card:hover{
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
}

.card-number{
    font-weight: bold;
    color: #000;
    margin: 0;
    padding: 0.5em;
    justify-self: start;
}



.solve-card-image{
  
}
.img_head_text{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap : 1rem ;
    justify-content: center; 
}
.solve-card-title{
    height: 50px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
}

.solve-card-description{
  
    font-size: 0.7rem;
    color: #728197;
}


@media (min-width: 768px) and (max-width: 1024px){
   
    .solve-title{
        font-size: 3rem;
    }

    .solve-sub-title{
        font-size: 1rem;
        /* max-width: 80%; */
    }

   

    .solve-card-title{
        font-size: 1rem;
    }

    .solve-card-description{
        font-size: 0.75rem;
    }
}

@media (min-width: 1024.5px){
    .problems{
        flex-direction: row;
    }

    .solve-title{
        font-size: 3rem;
    }

    .solve-sub-title{
        font-size: 1rem;
        /* max-width: 80%; */
    }

   

    .solve-card-title{
        font-size: 1.2rem;
    }

    .solve-card-description{
        font-size: 0.8rem;
    }
    
}

@media only screen and (max-width: 768px) {
    .problems {
        flex-direction: column;
    }

    .problem-container {
        width: 100%;
        margin: 0.5em 0;
        height: auto; /* Allows the height to adjust based on content */
    }
    .solve-sub-title {
        max-width: 100%;
    }
    .solve-text{
        font-size: 1.9rem;
        width: 100% !important;
       }
       
.solve-container{
    background-color: #f6f8fb;
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
   
}

}
