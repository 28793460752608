
.faq-container{
    max-width: 1200px;
    margin: 4em auto;
}

.faq-title{
    font-size: 2rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    display: grid;
    grid-template-columns: 1em 1fr 1em;
    grid-template-areas: 
    ". title ."
    ". asked .";
    gap: 0.5em;
    margin: 1.5rem 0;
}

.frequently{ grid-area: title; }
.sub-title{ grid-area: asked; }
.questions{
    color: #09a7b3;
}

.faq-title p{
    margin: 0;
}


.faq-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em auto;
    gap: 2em;
}

.faq-questions{
    display: flex;
    width: 80%;
    /* todo: handle the accordino component*/
}

.faq-enquiry{
    border: 1px solid #09a7b3;
    border-radius: 5px;
    padding: 1em;
  
    width: 80%;
}

.faq-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1em;
}


.faq-card-title{
    font-size: 1.5rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    margin: 0.5em 0;
}

.faq-card-text{
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    margin: 0.5em 0;
    max-width: 70%;
}

#faq-card-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 1em;
    gap: 1em;
    margin: 0.5em 0;
    box-shadow: 0 4px 6px #8a8d8d, 0 1px 3px #09A7B3;
  
    /* Transition for smooth animation */
    transition: box-shadow 0.4s, transform 0.4s;
    cursor: pointer;
}
#faq-card-btn:hover {
    /* Adjust box-shadow on hover */
   
    transform: scale(1.1);
  }


#faq-card-btn img{
    width: 1.8em;
}

.faq-card-btn-text{
    font-family: Inter, sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
}

@media (min-width: 800px) {
    .faqs-frame-parent{
        margin: 2em auto;
        width: 90%;
    }


    .faq-title{
        display: flex;
        justify-content: center;
    }

    .faq-section{
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

    }

    .faq-enquiry{
        width: 40%;
    }
}