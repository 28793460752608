footer{
    font-family: "Inter", sans-serif;
    color: #728197;
    /* max-width: 1200px; */
    /* width: 80%; */
}

.info-container{
    margin: 0 auto;
}

.about-us-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 2em 10em;
    padding: 2em;
}

.store-title, .support-title, .company-contact-details-title{
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 0.5rem;
}

.info-sub-text {
    cursor: pointer;
    color: #728197;
    background: linear-gradient(#09a7b3, #09a7b3) bottom / 0 .1em no-repeat;
    transition: 0.8s background-size;
    font-size: 1rem;
}
.info-sub-text:hover {
    background-size: 100% .1em;
}

.info-sub-text_d {
    cursor: pointer;
    color: #868787;
  
    font-size: 1rem;
}

.link-details{
    color: #09a7b3;
    text-decoration: underline;
    font-size: 1rem;
}

.support, .stores, .company-contact-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 1rem 0;
    gap: 1rem;
}

.copyright-container{
    display: flex;

    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;
}

.company-info, .currency-info, .company-socials{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.language{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.englishUs{
    text-decoration: underline;
}

.separator, .separator-80{
    border-bottom: 1px solid #cbd4e1;
    margin: 0.5em auto;
}

.separator-80{
    width: 80%;
}

.social-icon{
    width: 20px;
    cursor: pointer;
    /* border: 1px solid #cbd4e1; */
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.modal-content {
background-color:  #09a7b3;
padding: 10px;
border-radius: 10px;
position: relative;
max-width: 90%;
max-height: 90%;
overflow: hidden;
}

.close-button {
position: absolute;
top: 15px;
font-size: 20px;
right: 15px;
cursor: pointer;
background: white;
border: 1px solid  #09a7b3;
border-radius: 10px;
padding: 10px ;
color: #000000;
}

.map-integrated{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-left: 0;
}


@media (max-width: 768px) {
    .about-us-container{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .support, .stores, .company-contact-details{
        display: flex;
        justify-content: center;
        align-items: flex-start;


        padding: 1rem 0;
        gap: 1rem;
    }
    
    .copyright-container{
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 1rem 0;
    }
    
    .company-info, .currency-info, .company-socials{
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
    }
}