.confirmationgroupParent {
    display: flex;
    flex-direction: column;
   align-items: center;
   justify-content: center;
    font-family: "Inter",sans-serif;
     margin-top: 5rem ;
    margin-bottom: 7rem ;
    box-sizing: border-box;
    font-size: 22px;

}

//section 1
.confirmationframeWrapper {
display: flex;
align-items: center;
flex-direction: column; 

}
.confirmationmsg{
    display: flex;
  
    
    gap : 1rem ;   
}
.confirmationbookingConfirmed {
    color: #17bf33;
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem ;
}
.confirmationitsOfficial{
 font-weight: 700;
 text-align: center;
}
.confirmationpositiveSolidIcon1 {
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.confirmationyourReservationIs1 {
    align-self: stretch;
    font-size: 16px;;
    text-align: center;
    line-height: 24px;
    color: #728197;
    margin-top: 1rem;

}

//section_2
.confirmation_details_Sections{
    margin-top: 3rem ;
  display: flex;
  margin-bottom: 3rem ;
  border: 1px solid #CBD4E1 ;
  width: 45rem ;
  border-radius: 15px;  

}
.confirmationimageIcon{
    width: 15rem ;
    height: 15rem ;
    border-top-left-radius: 15px; 
    border-bottom-left-radius: 15px; 

}
.confirmation_details_information{
    display: flex;
    margin: 2rem ;
    gap : 1rem ;
    flex-direction: column;
  
}
.address_mappin{
    margin-top: 0.5rem;
    display: flex ;
    gap : 0.5rem ;
    flex-direction: row;
    align-items: center;
   // justify-content: center;
    font-weight: 500;
    font-size: 16px ;
}
.confirmationbordeauxGetaway{
    font-size: 25px;
    font-weight: 600;
}
.confirmationid{
    display: flex;
    gap : 0.5rem ;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    background:#DABEEA;
    border-radius: 8px;
    padding : 0.6rem ;

}
.confirmationdates{
    font-size: 15px;
     color: #728197 ;
}


//section_3
.confirmation_bottom_buttons{
    display: flex;
    font-size: 16px;
    margin-top: 2rem ;
    font-weight: 600;
    gap :2rem ;
}
.confirmationbutton1{
    background: #09A7B3;
    width: 15rem ;
    border: 2px solid #09A7B3;
    padding: 0.5rem ;
    border-radius: 8px;
   

}
.confirmationlabel_1{
    color: white;
    text-align: center;
    text-decoration: none;
}
.confirmationlabel_2{
    text-align: center;
   color:#09A7B3;
}
.confirmationbutton2{
    width: 15rem ;
    padding: 0.5rem ;
    border-radius: 8px;
    border: 2px solid #09A7B3;

}






//responsice design 
@media (max-width: 768px) and (min-width: 320px) {
    .confirmation_details_Sections{
        margin-top: 5rem ;
        margin-left: 2rem;
        margin-right: 2rem;
       display: flex;
       width: 20rem;
      flex-direction: column;
      border: 1px solid #CBD4E1 ;
      border-radius: 15px;  
      gap : 2rem ;
    }
    .confirmationimageIcon{
        width: 20rem ;
        height: 17rem ;
        border-top-left-radius: 15px; 
        border-top-right-radius: 15px; 
        border-bottom-left-radius: 0px ;
        
    }

    .confirmation_bottom_buttons{
        display: flex;
        flex-direction :column ;
        font-size: 16px;
        margin-top: 2rem ;
        font-weight: 600;
        gap :2rem ;
    } 
    .confirmation_details_Sections{
        margin-top: 2rem ;
    
    }
    .address_mappin{
        margin-top: 0.2rem;
        display: flex ;
        font-size: 12px ;
    }
    .confirmationbordeauxGetaway{
        font-size: 20px;
        font-weight: 600;
    }
    .confirmationdates{
        font-size: 12px;
    
    }
}





