
.checkoutbreadcrumbToDetachParent{
    display: flex;
    justify-content: space-between;
    font-family: "Inter",sans-serif;
    padding:3rem ; 
}
.checkoutlink{
    font-size: 0.8rem;
}
.prev_page_links_checkout{
    align-items: center;
    display: flex;
    gap: 0.8rem ;
}
.checkout_section_left{
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 2rem ;
}
.checkout_booking_details_headline{
    display: flex;
    align-items: center;
    gap  : 2rem ;
}
.checkoutbookingConfirmation{
    font-size: 1.8rem;
    font-weight: 600;
}
.checkoutyourBooking_details{
    display: flex;
    flex-direction: column;
    gap: 1.5rem ;
    border  :  1px solid #CBD4E1;
    border-radius: 15px ;
    padding: 1.5rem;
}

.checkoutyourBooking_headline , .checkout_map_address{
    font-weight: 600;
}
.checkoutdate{
    font-size: 0.8rem;
    color: grey;
}
.checkoutdateParent ,.shelfand_shelf_no{
    display: flex;
    flex-direction: column;
    gap : 0.3rem ;
}
.checkout_date_edit{
    display: flex;
    justify-content: space-between;
}
.checkout_shelf_edit{
    display: flex;
    justify-content: space-between;
}
.countshlefincheckout{
    display: flex;
    gap : 0.5rem ; 
}

.checkout_payment_container{
    display: flex ;
    flex-direction: column;
    border  : 1px solid #CBD4E1;
    border-radius: 20px;
}
.checkout_img_shelf{
    height: 250px;
    width: 400px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.checkout_breakdown_parameter{
    color: #728197;
}
.checkout_payment_breakdown{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap : 1rem ;
}
.checkout_details{
    display: flex;
    font-size: 1rem;
    justify-content: space-between;  
}
.checkout_store_name{
    border-bottom : 1px solid #CBD4E1;
    font-size: 1.5rem;
    padding: 0.3rem;
    font-weight: 600;
}
.checkoutbillingdetails_headline{
    font-weight: 600;
}
.checkoutlabel{
    color: white;
    background-color: #09a7b3; 
    padding: 0.8rem;
    border-radius: 8px;
    justify-content: center;
    text-align: center;
}
.checkout_Link{
    color: white;
    text-decoration: none;
    text-align: center;
}
.billing_components_checkout{
    display: flex;
    gap : 0.5rem ;
    flex-direction: column;
}
.checkout_billing_confirm_button{
    display: flex;
    flex-direction: column;
    gap : 1.5rem ;
}

// map issue 
.checkoutabout_map_section{
    display: flex ;
    flex-direction: column;
    gap: 1rem ;
    border  : 1px  solid #CBD4E1;
    border-radius: 15px;
    padding: 1.5rem; 
}
.checkout_address_mestion{
    display: flex;
    align-items: center;
    gap : 0.5rem ;
  }
.checkout_map {
    //display: none;
   }

@media (max-width: 768px) and (min-width: 320px) {
    .checkoutbreadcrumbToDetachParent{
        padding: 1rem ;
        display: flex;
        flex-direction: column;
        gap : 5rem ;
    }
    .checkoutlink{
        font-size: 0.6rem;
    }
    .prev_page_links_checkout{
        display: flex;
        gap: 0.5rem ;
    }
  .hide_on_mobile{
    display: none;
    }
  .checkout_section_left{
    gap: 2rem ;
    width: 100%;
    }
.checkout_booking_details_headline{
    display: flex;
    align-items: center;
    gap  : 1.5rem ; 
    }
    .checkout_store_name{
        font-size: 1.5rem;
    }
.checkoutbookingConfirmation{
    font-size: 1.5rem;
    font-weight: 600;
}
.checkoutyourBooking_details{
    padding: 1rem;
}
.checkout_date_shelf_no{
    font-size: 0.9rem;
}
.checkout_img_shelf{
    height: 100%;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.checkout_address_mestion{
    font-size: 0.8rem;
}
.checkout_mappin{
    transform: scale(0.9);
}
    
}