
.aboutgoalourMission {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 5rem;
    font-family: "Inter", sans-serif;
    box-sizing: border-box; // Include padding in the width/height calculations
}

.aboutgoalrectangleParent {
    border: 1px solid #d4d4d4;
    padding: 1rem;
    width: 100%; // This will now respect the padding of the parent
    border-radius: 10px;
    box-sizing: border-box; // Ensures padding and border are included in the width
}
.aboutgoalrectangleParent:hover {
    transform: scale(1.05);
    transition-duration: 0.5s;
    background: #ffffff;
    border: 1px solid #09A7B3;
}

.aboutgoalsectionHeading{
    display: flex;
    flex-direction: column;
    gap : 1rem  ;
    align-items: center;
    justify-content: center;
}
.aboutgoalourMission1{
    font-size: 2rem;
}
.aboutgoalmission{
    color :#09A7B3 ;
}

.aboutgoalourMissionIs1{
    text-align: center;
    color: #728197;
    font-size: 1.2rem;
}
.aboutgoalvalueProps{
display: flex;
flex-direction: row;
gap : 1rem ;
}
.data_text_img_about_goal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.aboutgoalsynergyInRetailContainer{
    font-size: 1.2rem;
font-weight: 600;
}




@media (max-width: 768px) and (min-width: 320px) { 
    .aboutgoalrectangleParent{

        border: 1px solid #d4d4d4;
        padding: 1rem;
         width:100% ;
        border-radius: 10px;
        
        }
        .aboutgoalsynergyInRetailContainer{
            font-size: 1rem;
        font-weight: 500;
        }
        .data_text_img_about_goal{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0.8rem;
        }
        
    .aboutgoalvalueProps{
        display: flex;
       width: 100%;
        flex-direction: column;
        gap : 1rem ;
        }
        
    .aboutgoalourMission{
        padding: 2rem;
        
    }
    .aboutgoalourMission1{
        font-size: 1.5rem;
    }
    
.aboutgoalourMissionIs1{
    text-align: center;
    color: #728197;
    font-size: 0.8rem;
}


}