
.business-info-container{
    box-sizing: border-box;
    margin: 1em;
    padding: 0.5em;
    font-family: Inter, sans-serif;
}

.business-info-cards{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.business-info-card-item{
    border: 1px solid #f6f6f6;
    border-radius: 20px;
    padding: 1.5em ;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.2);
    width: 250px;
    height: 300px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
}


.business-info-card-item:hover{
    box-shadow: 5px 5px 25px 15px rgba(9, 167, 179, 0.2);
}

.business-info-card-image{
    width: 200px;
    margin: 0 auto;
}


.business-info-card-body{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.business-info-card-title{
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0.5em 0;
}


.business-info-card-text{
    font-size: 0.85rem;
    margin: 0.5em 0;
    max-width: 200px;
}


@media (max-width: 1023.8px){
    .business-info-card-item{
        padding: 1.25em;
        width: 200px;
        height: 300px;
    }

    .business-info-card-image{
        width: 180px;
    }

    .business-info-card-title{
        font-size: 1.25rem;
    }
    
    .business-info-card-text{
        max-width: 200px;
    }
}


@media (max-width: 768px){

    .business-info-cards{
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 2em;
    }

    .business-info-card-item{
        padding: 1em ;
        width: 300px;
        height: 350px;
    }

}