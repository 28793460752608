

.rent-container {
    position: relative;

    width: 100%;
   
    height: auto;
    font-family: Inter, sans-serif;
    display: flex;
 overflow-x: hidden;
 overflow-y: block;
    justify-content: center;
    /* border: 5px solid black; */
}


.rent-shelf-bg img {
    max-width: 100vw;
    margin-top: -2.5% ;
 

   
}
.hero_img_bg{
width: 100vw;
}

.rent-text-container { 
    /* border: 5px solid red; */
    position: absolute;
    top: 45%; 
    left: 30%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    max-width: 80%;
}

.beta-message {
    width: 75%;
    border-radius: 20px;
    background-color: #e9ebec;

}

.rent-beta-message {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.875em;
    padding: 0.35em;
    color: #125465;
    font-weight: 600;
}

.rent-new-notifier{
    width: 30%;
    font-size: 0.75rem;
    padding: 0.2em 0;
    text-align: center;
    background-color: #125465;
    color: #ffffff;
    border-radius: 20px;
    font-weight: 300;
}

.rent-text-heading{
    font-size: 4.5rem;
    font-weight: 600;
    color: #125465;
    margin: .25em 0;
}

.text-heading-first{
    display: flex;
    gap: 0.2em;
}

.text-highlight-shelf{
    display: flex;
    flex-direction: column;
    align-items: center;

    animation: floatAnimation 3s ease-in-out infinite;
}

.rent-shelf-text{
    font-weight: 600;
    color: #000000;
}

.rent-shelf-smile-image{
    display: flex;
    justify-content: center;
    align-items: center;
}


.rent-shelf-smile-image img{
    width: 100%;
}

.rent-text-subheading{
    font-size: 1rem;
    width: 80vh;
    color: #000000;
    margin: 1em 0 2em;
}

.rent-btn-search{
    display: flex;
    align-items: center;
    gap: 1.5em;
    font-size: 1rem;
}

.rent-synergy-btn {
   
    display: flex;
    flex-direction: row;
    gap : 2rem ;
  
  }
  .rent-button-links{
    padding: 12px 16px ;
    border-radius: 50px;
    background: linear-gradient(95.02deg, #116595, #09617d);
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
  
    /* Add box-shadow */
    box-shadow: 0 4px 6px #8a8d8d, 0 1px 3px #09A7B3;
  
    /* Transition for smooth animation */
    transition: box-shadow 0.4s, transform 0.4s;
  }
  .rent-button-links:hover {
    /* Adjust box-shadow on hover */
   
    transform: scale(1.06);
  }
  
  
  .early_acess_btn{
    padding: 0.75em;
    
    border-radius: 5px;
    background: linear-gradient(95.02deg, #09A7B3, #09A7B3);
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    /* Add box-shadow */
    box-shadow: 0 4px 6px #8a8d8d, 0 1px 3px #09A7B3;
  
    /* Transition for smooth animation */
    transition: box-shadow 0.4s, transform 0.4s;
  }
  .early_acess_btn:hover {
    /* Adjust box-shadow on hover */
   
    transform: scale(1.2);
  }
  
.rent-synergy-search{
    display: flex;
    align-items: center;
    gap: 0.75em;

    border-radius: 25px;
    border: none;
    background-color: #ffffff;
    padding: 0.75em;
}

.rent-synergy-search > img{
    width: 30px;
}

.rent-synergy-search-bar{
    width: 100%;
    border: none;
    outline: none;
}

.rent-synergy-search-bar:focus{
    outline: none;
}

.overlay {
    position: fixed;
    
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .form-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
  }
  
  
@media screen and (max-width: 1024px){

    .rent-text-container {
        top: 43%;
        left: 30%;
        transform: translate(-50%, -50%);
    }

    .text-heading-first{
        gap: 0;
    }
    
    .rent-text-heading{
        font-size: 3rem;
        margin: .25em 0;
    }

    .beta-message{
        width: 80%;
    }

    .rent-beta-message{
        font-size: 0.75rem;
    }

    .rent-new-notifier{
        font-size: 0.625rem;
    }

    .text-highlight-shelf{
        width: 40%;
    }

    .rent-shelf-smile-image img{
        width: 70%;
    }

    .rent-text-subheading{
        font-size: 0.875rem;
    }
    
    .rent-button-links {
        font-size: 0.75rem;
    }

    .rent-synergy-search{
        font-size: 0.75rem;
        padding: 0.625em 0.5em;
    }


    .rent-synergy-search > img{
        width: 20px;
    }

    .rent-synergy-search-bar{
        width: 90%;
    }
}

@media screen and (max-width: 768px) {

    .rent-container{
        width: 100%;
        /* height: 50vh; */
        background-color:  #F6F8FB; 
        background-image: #F6F8FB;

        margin: 0;
        max-width: none;
    }

    .rent-text-container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
    }

    .text-highlight-shelf{
        width: 30%;
    }

    .rent-text-heading{
        font-size: 2rem;
        margin: .25em 0;
    }

    .text-heading-first{
        justify-content: center;
    }

    .beta-message{
        width: 90%;
        margin: 0 auto 0.625em;
    }
    
    .rent-beta-message{
        font-size: 0.75rem;
    }

    .rent-new-notifier{
        font-size: 0.625rem;
    }

    .rent-text-subheading{
        font-size: 0.875rem;
    }
    
    .rent-btn-search{
        justify-content: center;
        align-items: flex-end;
    }

    .rent-button-links {
        font-size: 0.75rem;
    }


}

@media screen and (max-width: 495px) {

    .rent-container{
        /* width: 100%; */
       
    }
    .rent-synergy-btn {
   
        display: flex;
        flex-direction: column;
        gap : 2rem ;
      
      }
    .rent-text-container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        width: 100%;
        text-align: center;
    }
    
    .rent-text-heading{
        font-size: 1.25rem;
        margin: .25em 0;
    }

    .text-heading-first{
        justify-content: center;
    }

    .beta-message{
        width: 100%;
        margin: 0 auto 0.25em;   
    }
    
    .rent-beta-message{
        font-size: 0.5rem;
    }

    .rent-new-notifier{
        font-size: 0.5rem;
    }

    .rent-text-subheading{
        font-size: 0.625rem;
    }
    
    
    .rent-btn-search{
      
       
        justify-content: center;
        /* align-items: baseline; */
        gap: 0.5em;
    }
    
    
       

      
   
        
    



    .rent-synergy-search > img{
        width: 10px;
    }

    .rent-synergy-search{
        align-items: center;
    }
    
    .rent-synergy-search-bar, .rent-synergy-search{
        padding: 0.25em;
        font-size: 0.5rem;
    }
}

  
@keyframes floatAnimation {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px); 
    }
}

/*early access    */


  