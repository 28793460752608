.teamgroupParent {
padding: 5rem;
margin-top: 3rem;
  width: 100%;
  display: flex;
  gap : 4rem ;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px; 
  color: #728197; 
  font-family: 'Inter' , sans-serif ; 
}
.teammeetOurParent {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.teamdiscoverTheBrilliance1{
  font-size: 0.8rem;
  text-align: center;
   color: #728197; 
  line-height: 24px;
}
.teamamazingTeamTxtContainer{
  font-size: 2.4rem;
  font-weight: 600;
}
.teammember4Parent {
   display: flex;
   flex-direction: row;

  font-size:  18px; 
  color: #1a212b; 

}
.teammember1{
display: flex;
flex-direction: column;
gap : 0.5rem ;
align-items: center;
min-width: 180px;
box-sizing: border-box;


}
.teamuserpicIcon{
  width: 90px;
  height: 90px;
  border-radius: 50px;
  transition: all 0.5s ease;
}
.teamuserpicIcon:hover{
  transform: scale(1.1);
}

.teammembername{
 
  color: #1a212b;  font-family: 'Inter' , sans-serif ; 
  text-align: center; 
  font-size: 1rem;
  font-weight: 400; 

}
.teamrole{
  font-family: 'Inter' , sans-serif ; 
font-size: 0.8rem;
}
.socialinfoteam{
  display: flex;
  flex-direction: row;
gap : 1.2rem ;
  cursor: pointer;
}



//....................................//





@media (max-width: 768px) and (min-width: 320px) {
  .socialinfoteam{
    display: flex;
    flex-direction: row;
  gap : 0.6rem ;
    cursor: pointer;
  }
  
  
  .teamuserpicIcon{
    width: 70px;
    height: 70px;
    border-radius: 50px;
    transition: all 0.5s ease;
  }
  .teammember1{
    display: flex;
    flex-direction: column;
    gap : 0.5rem ;
    align-items: center;
    min-width: 120px;
    box-sizing: border-box;
    
    
    }
  .teamgroupParent {
    padding: 2rem;
    margin-top: 3rem;
      width: 100%;
      display: flex;
      gap : 3rem ;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px; 
      color: #728197; 
      font-family: 'Inter' , sans-serif ; 
    }

    .teammember4Parent {
      display: grid; /* Use grid layout */
      grid-template-columns: repeat(2, 1fr); /* 3 equal columns */
      grid-template-rows: repeat(2, auto); /* 2 rows with automatic height based on content */
      row-gap: 3rem;
      gap:2rem ;
      justify-items: center; /* Center items horizontally */
      align-items: center; /* Center items vertically */
    
   }

  .social_team{
    width: 12px;
    height: 12px;
  }




}

