.sdp_container{
    padding: 4rem  ;
  }
  .sdpbigparent{
      width: 100%;
      display: flex ;
      flex-direction: row ;
      gap: 20px;
       
  }
  .sdppart1{
      flex-grow: 1;
      min-width: 80vh;
     
  }
  .sdppart1content {
      max-width: 712px;
  } 
  
  .totalshelfavailableandcalender{
      display: flex;
      flex-direction: row;
   justify-content: space-between;
  }
  
  .totalAvailableShelves {
      font-size: 22px;
      line-height: 24px;
      font-weight: 600;
     
      font-family: "Inter",sans-serif;
      color: #1a212b;
      text-align: left;
      }
  
      .sdpplaceholder{
          font-size: 0.8rem;
        //  background:  #eff1f5;
          text-align: center;
          padding: 5px ;
          border-radius: 5px ;
      }
      .calender_icon_dates{
          text-align: center;
          background:  #eff1f5;
          border-radius: 5px ;
          padding: 2px ;
          align-items: center;
       justify-content: space-between;
          padding-right: 8px;
          display: flex;
          font-family: "Inter",sans-serif;
          
      }
  .sdpselectField{
          display: flex;
          flex-direction: row;
          gap : 58px ;
   }
   .sdpicon1 {
     cursor: pointer;
    
    }
   
    .onclickopencalender {
      position: absolute;
      top: 940px;
      left: 100px ;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999; /* higher z-index to appear above other content */
  
    }

    .sdptags{
      font-family: "Inter",sans-serif ;
      display: flex;
      gap : 16px ;
      margin-top: 20px ;
  
  }
  
  .sdptagboxall{
      width: 39px ;
      height: 34px ;
      border-radius: 8px ;
      border: 1px solid #cbd4e1; 
      box-sizing: border-box;
      padding-top: 8px;
      padding-left:12px ;
      padding-right: 12px;
      padding-bottom: 8px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      color: #1a212b;
      text-align: left; 
  }
  .sdptagboxall:hover{
      border: 1px solid  #09a7b3;;
  }
  .sdptagbox{
      cursor: pointer;
      min-width:60px ;
      height: 34px ;
     
      border-radius: 8px ;
      border: 1px solid #cbd4e1; 
      box-sizing: border-box;
      padding-top: 8px;
      padding-left:12px ;
      padding-right: 12px;
      padding-bottom: 8px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      font-family: "Inter",sans-serif;
      color: #1a212b;
      text-align: left; 
  
  }
  .sdptagbox:hover{
      border: 1px solid  #09a7b3;;
  }
  
  .sdpselfframeParent {
      display: flex;
      flex-direction: column;
      padding-top: 20px  ;
      box-sizing: border-box;
      text-align: left;
      font-size: 22px; 
      color: #1a212b;
      font-family: "Inter",sans-serif ;
  } 
  .sdpselfrackParent {
      flex-shrink: 0;
      display: flex;
      cursor: pointer;
      flex-direction: column;
  //   width: 600px ;
      gap: 24px;
      font-size: 16px;
  }
  
  
  
  
  // section details
  .sdpselfrack{
      // width: 120%;
  }
  .shelf_details_sdp:hover{
      border: 1px solid #09a7b3 ;
  }
  .shelf_image_sdp{
      width: 10vw;
      border-radius: 8px;
      height: 6rem;
  }
.icon_insight_sdp{
    position: absolute;
    display: flex;
    width: 70px;
    height: 70px;
    align-items: right;
    justify-content: right;
    box-sizing: border-box;
    right: -10px;
    top : -10px ;
    animation: blink 3s infinite ease-in-out;
}
@keyframes blink {
    0% {
      opacity: 1; /* Fully visible */
    }
    50% {
      opacity: 0; /* Fully invisible */
    }
    100% {
      opacity: 1; /* Back to fully visible */
    }
  }
.sdp_shelf_area{
    font-size: 0.9rem;
}
 
  .shelf_details_sdp{
      position: relative;
      border: 1px solid #cbd4e1;
      display: flex;
      box-sizing: border-box;
      border-radius: 10px;
      gap : 2rem ;
      overflow: hidden;
      padding: 1.2rem ;
  }
  .shelf_desc_rent_sdp{
    display: flex;
    align-items: center;
  }
  .shelf_rent_sdp{
  font-size: 1.2rem;
  font-weight: 600;
  }
  .shelf_desc_sdp{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }
  .shelf_rent_sdp_area{
    display: flex;
    gap : 2rem ;
  }
  .shelf_no_desc{
      display: flex;
      flex-direction: column;
      gap : 0.5rem ;
  }
  .sdp_check_box{
      display: flex;
  
      align-items:center;
      justify-content: right;
      gap : 0.5rem ;
  }
  .shelf_area{
   
      color: #000000;
  
      padding: 0.2rem;
      background: #D2B4D2;
      border-radius: 5px;
      font-size: 0.9rem;
  }
 


  
  // breakddown section 
  .line_sdp_billing{
   border-top: 1px solid #e2e2e2 ;
  }
  .sdpbreakbb{
 
      font-family: "Inter",sans-serif;
      border: 1px solid #e3e3e6;
      box-sizing: border-box;
      box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.2);  
      border-radius: 10px ;
       width : 320px ; 

       flex: none; /* Prevent flex-grow and flex-shrink */
       height: auto; /* The height will adjust based on the content inside */
       align-self: flex-start; /* Prevent stretching along the cross-axis */
  }
  .sdpbreakbutton{
      
      box-sizing: border-box;
      background: #09a7b3;;
      height: 45px;
      width: 100% ;
      border-radius: 10px ;
      padding: 12px 16px; 
     
  }
  .datepickedforrent{
      height: 30px ;
      border-start-start-radius : 8px  ;
      border-start-end-radius : 8px  ;
     // background : #F6F8FB;
      display: flex;
      border-bottom: 1px solid #cbd4e1; 
      padding-top: 16px;
      padding-bottom:16px ;
      padding-left:12px ;
      padding-right:12px;
  }
  .startdate{
      display: flex;
      flex-direction: column;
      width: 150px ;
      flex-grow: 1;
     }
     .enddate{
         text-align: right ;
         width: 150px ;
         display: flex;
        flex-direction: column;
        flex-grow: 1;
     }
  .datepicked1{ font-size: 12px;
     line-height: 18px;
     font-weight: 500;
     font-family: Inter;
     color: #1a212b;
     display: inline-block; 
  //    flex-grow: 1;
  }
  .datepicked2{
     font-size: 14px;
     line-height: 20px;
     font-family: Inter;
     color: #728197;
     display: inline-block; 
  }
  .totalshlefpickeddata{
      display: flex;
  //   background : #F6F8FB;
      flex-direction: column;
      padding-left : 12px ;
      padding-right : 10px  ;
      padding-top : 10px ;
      padding-bottom : 10px ;
      border-bottom: 1px solid #cbd4e1; 
    
  }
   // promo code 
  
   .coupon_code_section{
     padding-top: 0.4rem;
   }
   .apply_code_division{
  padding-top: 1.2rem ;
  display: flex;
  gap : 1rem  ; 

   }
   .apply_coupon_button{
       border: 1px solid  #09a7b3;
       background: #09a7b3 !important;
       color: white;
       border-radius: 5px;
       width: 100%;
       text-align: center;
       padding: 0.4rem;
       cursor: pointer;
       font-size: 12px;
   }
   .have_a_coupon_code_title{
  text-decoration: underline;
  font-size: 12px; 
   text-align: right;
   cursor: pointer;    
   }
   .have_a_coupon_code_title:hover{
       color:#09a7b3 ;
   }
   .coupon_input_section{
   border-radius: 5px;
padding: 0.4rem;
   border: 1px solid   #d2e2e6; 
       background: #ffffff;
   }
   .coupon_input_section:focus {
       outline: none; /* Remove default focus outline */
       border-color:   #d2e2e6; /* Prevent border color from changing */
   }
  
  
  .gtcheckout1{
   align-items: center;
   justify-content: center;
   text-align: center;
   color: white;
  }

  //   new 
  .sdpmaincomponent {
      font-family: 'Inter', sans-serif;
      margin-top: 50px;
      max-width: 100%; /* Set maximum width */
    
  }

  .sdpbreakframeParent{
      font-family: "Inter",sans-serif;
      width : 472px ;
      padding-left: 38px ;
      padding-right: 64px ;
      padding-top: 20px ;
  }
  .sdpbreakrowParent{
      border: 1px solid #9aa8bc;
      box-sizing: border-box;
   background: #f6f8fb;;
   //border-radius: 20px;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
  
    //  padding: 10px ;
  }
  .sdpbreakattribute4{
      
      padding : 10px ;
    
  }
  .sdpbreakrow{
      display: flex ;
      flex-direction: row ;
      border: 1px solid #9aa8bc;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-sizing: border-box;
      padding: 10px ;
  }
  .sdpbreakattribute1{
      font-size: 12px ;
      color : #1a212b; 
      width: 140px;
      text-align: left;
      font-family: "Inter",sans-serif ;
      font-weight: 500;
  }
  .sdpbreakdiv1{
      // width: 100px;
      width: 50%;
      text-align: right;
  }
  .sdpbreakattribute2{
      width: 140px;
     margin-left: 120px ;
  }
  .sdpbreakattribute1E{
      font-size: 12px ;
      color : #1a212b; 
      font-weight: 500;
  }
  .sdpbreakvalue{
      color :#728197; ;
      font-family: "Inter",sans-serif ; 
      font-size: 14px ;
  }
  .sdpbreakbookingBreakup{
       font-size:  16px;
       color : #1a212b; 
       font-family: "Inter",sans-serif ;
       font-weight: 600;
      //  width: 280px ;
       width: 100% ;
  }
  .sdpbreakrow3{
      display: flex ;
      color :#728197; ;
      font-family: "Inter",sans-serif ; 
      font-size: 14px ;
      line-height: 22px ;  
  }

  .sdp_box_button{
   padding: 0.8rem;  
  }
  .sdp_shelf_no{
    font-size: 0.9rem;
   }
  .sdpbreakrow2{
       padding  : 15px;
  }
  .sdpbreakdiv6{
      // margin-left: 20px ;
      width: 50%;
      text-align: end;
  }
  .billing_details_sdp{
   margin-top: 15px ;
   display: flex;
   flex-direction: column;
   gap : 0.3rem ;
  }
  .sdp_self_desc{
    font-size: 0.8rem;
   }
  .sdpbreakrow8{
    color: #1a212b;
    font-size:  16px;
    font-weight: 600;
    font-family: "Inter",sans-serif ;
    display: flex ;
   margin-top: 10px ;
  }
  
  .sdpbreaktotalShelves{
      width : 50% ;
      // height: 24px ;
      
  }
  .rightcalenderarrow{
   padding: 0.5em;
   width : 15px ;
   height: 15px ;
  }

          .totalShelvesPicked {
             
              font-size: 12px;
              line-height: 18px;
              font-weight: 500;
              font-family: Inter;
              color: #1a212b;
              text-align: left;
              display: inline-block;
              } 
  .shelves {
     
      font-size: 14px;
      line-height: 20px; 
      font-family: Inter;
      color: #728197;
      text-align: left;
      display: inline-block;
      }
    // responsive designs 
    @media (max-width: 1280px) {
      .sdpbigparent{
          flex-wrap: wrap;
      }
    }
  
      @media (max-width: 768px) and (min-width: 320px) {
  
      .sdp_container{
          
        padding: 1.5rem;
       
         }
         .sdpbigparent{
           
             display: flex ;
           flex-direction: column;
           gap : 3rem ;
              
         }
         .sdppart1{
             width: 100%;
             min-width: unset;
         }
  
      
          .totalshelfavailableandcalender{
              display: flex;
              flex-direction: column;
              gap : 30px ;
          
              width: 100% ;
                 
      }
      .sdpplaceholder{
      
            text-align: center;
            padding: 5px ;
            border-radius: 5px ;
        }
        .calender_icon_dates{
            text-align: center;
            background:  #eff1f5;
            border-radius: 5px ;
            padding: 2px ;
            padding-right: 8px;
            display: flex;
            font-family: "Inter",sans-serif;     
           }
        .totalAvailableShelves {
          font-size: 1.2rem;
          line-height: 24px;
          font-weight: 600;
          font-family: "Inter",sans-serif;
          color: #1a212b;
          text-align: left;
          }
          .onclickopencalender {
              position: absolute;
              top: 700px;
             // width: 400px;
              left:1px ;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 999; /* higher z-index to appear above other content */
            }
            .sdpselfframeParent {
              display: flex;
              flex-direction: column;
              padding-top: 20px  ;
              width: 100%;
              box-sizing: border-box;
              text-align: left;
              font-size: 22px; 
              color: #1a212b;
              font-family: "Inter",sans-serif ;
          } 
          .sdpselfrackParent {
              flex-shrink: 0;
              display: flex;
              cursor: pointer;
              flex-direction: column;
              width: 100%;
              gap: 24px;
              font-size: 16px;
          }
          .sdpselectField{
              display: flex;
              flex-direction: row;
              gap : 58px ;
       }
       // sdp rack section
       .sdpselfrack{
          width: 100%;
      }
      .sdp_shelf_no{
       font-size: 1.2rem;
      }
      .shelf_details_sdp:hover{
          border: 1px solid #09a7b3 ;
      }
      .sdp_self_desc{
       font-size: 1rem;
      }
      .shelf_image_sdp{
          width: 100%;
          border-radius: 8px;
          height: 25vh;
      }
      .shelf_details_sdp{
          border: 1px solid #cbd4e1;
          display: flex;
          flex-direction: column;
          border-radius: 10px;
          gap : 2rem ;
          padding: 1.5rem ;
      
      }
    

      .shelf_desc_rent_sdp{
      display: flex ;
      align-items: center;
      justify-content: left;
      }
      .shelf_rent_sdp{
      font-size: 1.2rem;
      font-weight: 600;
      }
      .shelf_desc_sdp{
          display: flex;
          flex-direction: column;
          gap : 1rem ;
      }
  


      .shelf_no_desc{
          display: flex;
          flex-direction: column;
          gap : 0.5rem ;
      }
      .sdp_check_box{
          display: flex;
          justify-content: left;
          text-align: left;
          align-items: left;
          gap : 1rem ;
      }
      .shelf_area{
          color: #9aa8bc;
          background-color:#d2b4d2;
       
             color: #000000;
          font-size: 15px;
      }
  
  
      // break down 
      .sdpbreakbb{
  
          font-family: "Inter",sans-serif;
          border: 1px solid #e3e3e3;
          box-sizing: border-box;
          border-radius: 10px ;
           width : 100%;   
      }
      .sdpbreakbutton{
       
          box-sizing: border-box;
          background: #09a7b3;;
          height: 48px;
          width: 100%;
          border-radius: 10px ;
          padding: 12px 16px; 
         
      }

      //calender
      .storeDescription{
        display: flex;
        flex-direction: column;
         gap : 10px ;
        background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background for text */
        color: #000;
        width: 70% !important; 
       align-items: center;
       justify-content: right;
       padding: 20px;
        border-radius: 8px;
        z-index: 1001; /* Make sure it sits above the background */
      }

     .icon_insight_sdp{
    position: absolute;
    display: flex;
    width: 70px;
    height: 70px;
    align-items: right;
    justify-content: right;
    box-sizing: border-box;
    right: -10px;
    top : -10px ;
    animation: blink 3s infinite ease-in-out;
}
     
.icon_insight_sdp_mobile{
    position: absolute;
    display: flex;
    width: 70px;
    height: 70px;
    align-items: right;
    justify-content: right;
    box-sizing: border-box;
    right: -10px;
    margin-top: -30px;
    animation: blink 3s infinite ease-in-out;  
}
  
  }




  //

 
  
  .image-container {
    display: inline-block;
  }
  
  .icon_insight_sdp {
    cursor: pointer;
  }
  
  .overlay {
    display: none; /* Hidden initially */
    position: fixed; /* This will make it cover the entire window */
    top: 0;
    left: 0;
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1000; /* Ensure the overlay is on top of other content */
    padding: 0px;
  }
  
  .storeDescription{
    display: flex;
    flex-direction: column;
     gap : 10px ;
    background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background for text */
    color: #000;
    width: 50%;
   align-items: center;
   justify-content: right;
   padding: 20px;
    border-radius: 8px;
    z-index: 1001; /* Make sure it sits above the background */
  }
  
  .image-container:hover .overlay {
    display: flex; /* Show the overlay on hover */
  }
  
  .close-btn {
    
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 10px;
  }
  
  

  