
//form 1 
.form1{
     font-family:  'Inter', sans-serif !important;
     padding-top: 4rem;
     padding: 2rem ;
     display: flex;
     
     justify-content: center;
     align-items: center;
}

.label ,.businesslabel{
  color: #728197;
  font-size: 0.8rem;
}
.fullfirstblock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px; 
  width: 60vw ;
    background-color: #fff; 
    border: 1px solid #9aa8bc; 
  }


  .form1firstblock {
    border-bottom: 1px solid  #cbd4e1;;
    box-sizing: border-box;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    gap: 4px;
    text-align: left;
    font-size:14px; 
    color: #1a212b;
  }
  .storeinfotitle {
   
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color:#1a212b;
    text-align: left;
    display: inline-block;
    }

    .businesstellUsAbout1 {
      font-size:  12px; 
      line-height: 18px;
       }

       .form{
         display: flex;
         flex-direction: column;
         padding: 1rem;
         gap : 1rem ;
      }


      .textField {
        display: flex;
        flex-direction: column;
        gap: 4px; 
    }
    .labelWrapper {
      display: flex;
      flex-direction: row;
      margin-top: 10px ;
     
    }
    .textarea_input_store_description {
      height: 4rem; /* Adjust height for multi-line text */
      padding: 10px; /* Adds padding inside the textarea */
      border: 1px solid  #cbd4e1;;
      border-radius: 5px;
      resize: none; /* Prevents manual resizing */
      box-sizing: border-box;
      font-family: inherit;
      line-height: 1.5; /* Ensures proper spacing between lines */
      vertical-align: top; /* Ensures text aligns to the top */
      overflow-y: auto; /* Allows vertical scrolling if content exceeds height */
  }
  
  .textarea_input_store_description:focus {
      outline: none; /* Removes default focus outline */
    
  }
  
   
#store_name_input{
  flex: 1;
  border-radius:  8px; 
  background-color:#fff ;
  border: 1px solid  #9aa8bc; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:12px 12px; 
  font-size:15px; 
}


.areaandcode{
  display: flex;
  flex-direction: row;
  gap: 1rem ;
  flex: 2;


}
.postcode_input {

  border-radius:  8px; 
  background-color:#fff ;
  border: 1px solid  #9aa8bc; 
  padding:12px 12px; 
  width: 93%;
  font-size:15px; 
  
} 

.store_area_input{
  width: 93%;
  border-radius:  8px; 
  background-color:#fff ;
  border: 1px solid  #9aa8bc; 
  padding:12px 12px; 
  font-size:15px; 
}

.selectField_postalcode {
  flex: 1;
}
.selectField_area {
 
  flex: 1;
}
.textArea {
 
  display: flex;
  flex-direction: column;
  gap:4px;
}
#input_address {
 
  border-radius:  8px; 
  background-color:#fff ;
  border: 1px solid  #9aa8bc; 
  padding:12px 16px; 
  font-size:15px; 
}

.selectFieldParent{
  display: flex;
  flex-direction: row;
  gap: 1rem ;
  flex: 2;
}
.selectField_select_country{
  flex: 1;
}
.selectField_select_city{
  flex: 1;
}
.placeholder {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.input_country , .input_city {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color:  #fff;
  border: 1px solid #9aa8bc; 
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px; 
  gap:  16px; 
  font-size:  16px;
}



@media (max-width: 768px) and (min-width: 320px) {

  .areaandcode{
    display: flex;
  flex-direction: column;
   gap : 0px ;
  }
.label{
  font-size: 0.8rem;
}

  .selectFieldParent{
    display: flex;
    flex-direction: row;
    gap: 0.5rem ;
    flex: 2;
  }
  .fullfirstblock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px; 
    width: 80vw ;
    background-color: #fff; 
    border: 1px solid #9aa8bc; 
  }


 }


.form_1_buttonContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px; /* Optional: adds some spacing between the buttons */
}

.form_1_buttonSave,
.form_1_buttonNext {
  flex: 1; /* Make both buttons take equal space */
  padding: 10px 20px;
  text-align: center;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
}

.form_1_buttonNext {
  text-decoration: none;
}

.form_1_buttonSave {
  background-color: #09a7b3 ; /* Example color */
  color: white;
  border-radius:  8px;
}

.form_1_buttonNext {
  background-color: #01b3c7 ; /* Example color */
  color: white;
  border-radius:  8px;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

/* Responsive layout for mobile devices */
@media (max-width: 768px) {
  .form_1_buttonContainer {
      flex-direction: column;
  }

  .form_1_buttonSave,
  .form_1_buttonNext {
      width: 100%; /* Full width in column layout */
      margin-bottom: 10px; /* Optional: spacing between buttons in column */
  }
}







// form 2 
.form1secondblock {
  font-family: "Inter", sans-serif;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Adjust for better mobile responsiveness */
}

.businessInformation {
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure it fits the container */
  max-width: 800px; /* Set a max-width for desktop */
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.businessstore {
display: flex;
}

.businessframeParent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.businessselectFieldParent {
  display: flex;
  flex-wrap: wrap; /* Allows elements to wrap on smaller screens */
  gap: 1rem;
  width: 100%; /* Ensure the parent takes full width */
}

.businessselectField {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 180px; /* Minimum width to prevent shrinking too much */
}

.business_input {
  flex: 1;
}
.customSelect_selected_property{
  width: 100%;
  height: 38px ;
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;
}
#demography_select {
  flex: 1;
  width: 100%;
}

#businessinput_field {
  flex: 1;
  padding: 12px 16px;
  width: 100%;
}

.businessform {
  display: flex;
  gap: 1rem;
  flex: 2;
  width: 100%;
 // justify-content: space-between;
 // flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
 // width: 100%;
 // box-sizing: border-box; /* Include padding and border in width calculation */
}

.linkform{  flex: 1;
  text-decoration: none;
  border-radius: 8px;
  background-color: #09a7b3;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  padding: 12px 22px;
  font-size: 14px;
  color: #fff;

}
#button_form12 {
  flex: 1;
  text-decoration: none;
  border-radius: 8px;
  background-color: #09a7b3;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border:   1px solid rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  padding: 12px 22px;
  font-size: 14px;
  color: #fff;
  width: 100%;

}
.open_close_time{
  display: flex;

  gap: 1rem ;
}

@media  ( min-width: 800px) {
  .linkform{
    text-decoration: none;
    border-radius:  8px;
    background-color: #09a7b3;
    display: flex;
    width: 100%;
   }
}
  

@media (max-width: 768px) {
  .form1secondblock {
    padding: 2rem; /* Reduce padding on smaller screens */
  }

  .businessInformation {
   // padding: 1rem; /* Adjust padding for smaller screens */
  }

  .businessform {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    gap: 1rem;
  }

  .linkform, 
  #button_form12 {
    max-width: 100%; /* Full width for stacked layout */
  }
}



@media (max-width: 768px) and (min-width: 320px) {
//form 2 
.businessstore {
padding: 0px ;
  border-bottom: 2px solid  rgb(117, 116, 116);
}
.businessframeParent {
  padding: 1rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


  //form 3
  .open_close_time{
    display: flex;
    flex-direction: column;
    gap: 1rem ;
  }

    
  .day{
    display: flex;
    flex-direction: column;
    flex : 6 ;
  padding: 0.5rem;
  justify-content: space-between;
  }
  .form13{
    padding: 2rem;
    padding-top: 4rem;
  }
  .linksummit{
    display: flex;
    padding: 1rem !important;
    flex-direction: column;
   gap : 1rem ;
  }
  


  //form 4
  .imgurlbutton{
    display:flex ;
    flex-direction: column;
    padding: 1rem ;
    width: 100%;
    gap : 20px ;
  
  }
  .form1fourthblock{
    display: flex;
    align-items: center;
    justify-content: center ;
    padding: 2rem ;
    font-family: "Inter",sans-serif ;             
      }
    
  .fourthblockuploadfiles {
    border-radius: 8px;
     width : 300px ;

    background-color: #fff;
    border: 2px dashed #9aa8bc;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 10.93px;
  }
  .fourthblockclickHereToContainer{
font-size: 12px;
  }
}
  



// form 3

@media  ( min-width: 800px) {
  .linkform_timing{
    text-decoration: none;
    border-radius:  8px;
    background-color: #09a7b3;
    display: flex;
    width: 100%;
   }
}
  
.form13{

 display: flex;
 //align-items: center;
  justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.form1thirdblock{   
  border-radius: 8px; 
  width: 60vw;
  border: 1px solid #9aa8bc; 
  box-sizing: border-box;
  align-items: center;
  font-family: "Inter",sans-serif ;
}

.day{
  display: flex;
  flex : 6 ;
padding: 2rem;
}
.days{
  display: flex;
  flex-direction: column;

}

#opentiming{
 
  border-radius: 8px; 
 // background-color: #fff; 
  border: 1px solid #9aa8bc; 
  box-sizing: border-box;
   width: 20vw;
   padding: 12px 16px;


}
#closetiming {
  width: 20vw;
  border-radius: 8px; 
 // background-color: #fff; 
  border: 1px solid #9aa8bc; 
  box-sizing: border-box;
  padding: 12px 16px;
}

.linksummit{
  display: flex;
  padding: 2rem;
  padding-top: 0rem;
  gap : 1rem ;

}
#button2 {
  text-decoration: none;
  border-radius:  8px;
  background-color: #09a7b3;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  border-color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  padding:16px 24px;
  font-size: 16px;
  color: #fff;
}
.linkform_timing{
  text-decoration: none;
  border-radius:  8px;
  background-color: #09a7b3;
  display: flex;

  flex-direction: row;
  cursor: pointer;
  border-color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  padding:16px 24px;
  font-size: 16px;
  color: #fff;
}
/* Base styles */
.closetime , .opentime{
  width: 100%; /* Container takes full width */
  //margin-bottom: 20px; /* Optional: adds space below the select */
}

.closetime select {
  width: 100%; /* Select field takes full width */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't exceed 100% width */
}
.opentime select {
  width: 100%; /* Select field takes full width */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding doesn't exceed 100% width */
}
/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .closetime select {
    font-size: 12px; /* Optional: adjust font size for smaller screens */
    padding: 6px; /* Optional: reduce padding for smaller screens */
  }
  .opentime select {
    font-size: 12px; /* Optional: adjust font size for smaller screens */
    padding: 6px; /* Optional: reduce padding for smaller screens */
  }
}

.dayname{
  flex: 2;
   width : 150px ;
   height: 20px ;
}
.storetitledata{
  align-self: stretch;
  position: relative;
  font-size:  12px; 
  line-height: 18px;

}
.storetitle{
  font-weight: 600 ;
  align-self: stretch;
  color:#1a212b;
  font-size: 16px;
} 

.fourthdatabox{
  margin : 24px ;
 } 
 .thirddata{
  border-bottom: 1px solid #cbd4e1;
  box-sizing: border-box;
  display: flex; 
 
} 

@media (max-width: 768px) and (min-width: 320px) {

.form13{
  display: flex;
   margin: 0;
 }
 .form1thirdblock{   
   border-radius: 8px; 
   width: 90vw;
   border: 1px solid #9aa8bc; 
   box-sizing: border-box;
   align-items: center;
   font-family: "Inter",sans-serif ;
 }
 #opentiming{
 
  border-radius: 8px; 
 // background-color: #fff; 
  border: 1px solid #9aa8bc; 
  box-sizing: border-box;
   width: 70vw;
   padding: 12px 16px;


}

#closetiming {
  width: 70vw;
  border-radius: 8px; 
 // background-color: #fff; 
  border: 1px solid #9aa8bc; 
  box-sizing: border-box;
  padding: 12px 16px;
}
}



// form 4
.form1fourthblock{
  display: flex;
  align-items: center;
  justify-content: center ;
  padding: 2rem ;
  font-family: "Inter",sans-serif ;             
    }
  
  .fourthblockimageSection1 {
      width: 70vw;
      border-radius: 12px;
      background-color:#fff; 
      border: 1px solid #9aa8bc;
      box-sizing: border-box;
   
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px; 
      color:#1a212b; 
      
  }
  .fourthblockstore {

  	display: flex;
    border-bottom: 1px solid #9aa8bc; 
    width: 100%;
  	flex-direction: column;
    text-align: left;
  //	padding:  24px;
  	gap: 4px;
}
.fourthblock_headinetext{
  padding: 24px;
}
.fourthblockstoreImages {
  line-height: 18px;
  font-weight: 600;
}
.fourthblocktellUsAbout1 {


  font-size:12px;
  line-height: 18px;
}

.fourthblockuploadfiles {
  border-radius: 8px;
  width : 65vw ;
  background-color: #fff;
  border: 1px dashed #9aa8bc;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 10px;
}
.fourthblockocticonupload161 {


  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
}
.fourthblocktextcontent {
  align-self: stretch;
  display: flex ;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px;
}
.fourthblocktoUploadOr {
  color: #052443;

}
.imgurlbutton{
  display:flex ;
  padding: 1rem ;
  width: 100%;
  gap : 30px ;

}
.loading_status{
margin-top: 1rem;
}
.drop_zone_and_submision{
  padding: 1rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap : 1rem ;
  align-items: center;
  justify-content: center;
}
#buttonk{
  flex: 1;
  text-decoration: none;
  border-radius:  8px;
  background-color: #09a7b3;
  display: flex;
  flex-direction: row;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
 width: 100%;
  align-items: center;
  justify-content: center;
  padding:12px 22px;
  font-size: 14px;
  color: #fff;
}
#buttonimg{
  flex: 1;
  //width: 100%;
  text-decoration: none;
  border-radius:  8px;
  background-color: #09a7b3;
  display: flex;
  flex-direction: row;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding:12px 22px;
  font-size: 14px;
  color: #fff;
}  
.fourthblockclickHereToContainer{
  font-size: 10px;

}
.progress-bar {
  width: 100%; /* Full width container */
  height: 10px;
  background-color: #e0e0e0; /* Light background color */
  position: relative;
  border-radius: 3px;
}

.progress {
  position: absolute;
  left: 0%; /* Start from the center */
  transform: translateX(0%); /* Move the starting point of the progress bar to the center */
  height: 100%;
  border-radius: 3px;
  background-color: #09a7b3;
  transition: width 0.5s ease; /* Smooth transition for progress bar */
}
.progress_percentage{
text-align: right;
font-size: 14px;

}
.progress_bar_container{
  margin-top: 1rem;
}
@media (max-width: 768px) and (min-width: 320px) {

.fourthblockimageSection1 {
  width: 90vw;
  border-radius: 12px;
  background-color:#fff; 
  border: 1px solid #9aa8bc;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px; 
  color:#1a212b; 
  
}


}
































