.profile_container_retail{
    padding: 2rem ;
    padding-left: 5rem;
}
.notification_container_retail{
    padding: 1rem ;
  
}
.retail_notification_sections{
    display: flex;
    flex-direction: row;
    background: white;
    justify-content: space-between;
      border : 1px solid  rgb(234, 241, 246) ;
      padding: 0.7rem ;
      font-weight: 500;
      border-radius: 5px ;
  align-items: center;
    color: #1A212B;
    font-size: 0.8rem;
  }
.placeholder_general_retail{
    background: #ffffff;
    border-radius: 5px ;
    height : 2vh ;
    width: 20vw;
   padding: 10px;
    border: none;
  }
  input:focus {
  
    outline: none; /* Remove default outline */
  }
  .profile_container_retail{
    display: flex;
    flex-direction: column;
    gap : 1rem ;
  }
.profile_data_retail_headline_placeholder{
    display: flex;
    flex-direction: column;
    gap : 0.4rem ;
  }

  //privacy
  .privacy_container_retail{
    margin: 2rem;
    display: flex;
    flex-direction: row;
      border : 1px solid  rgb(234, 241, 246) ;
      padding: 0.7rem ;
      background: white;
      justify-content: space-between;
      border-radius: 5px ;
      align-items: center;
      color: #1A212B;
       width: 60vw ;
      padding-left: 1rem ;
      font-size: 0.8rem;
  }

  .privacy_section_retail{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap : 1rem ;
  }


  @media (max-width: 768px) and (min-width: 320px) {
    .profile_container_retail{
      padding: 1rem ;
      padding-left: 3rem;
  }
  .placeholder_general_retail{
    background: #ffffff;
    border-radius: 5px ;
    height : 2vh ;
    width: 50vw;
   padding: 10px;
    border: none;
  }
  .privacy_container_retail{
    margin: 1rem;
      padding: 0.5rem ;
      background: white;
      border-radius: 5px ;
       width: 85vw ;
      padding-left: 0.5rem ;
      font-size: 0.7rem;
  }
  .privacy_section_retail{
    gap : 1rem ;
  }
  .change_password_ecomm_button{
    font-size: 0.5rem;
 }
 .password_change_text_ecomm_subhedline{
  width: 45vw;
  font-size: 0.5rem ;
}
 
// notifications 

.notification_container_retail{
  padding: 1rem ;
}
.retail_notification_sections{
    padding: 0.5rem ;
  font-size: 0.57rem;
}
.toggle_notification{
  transform: scale(0.7);
}

}