.supermarketoptions{
  margin : 1rem  ;
  padding-left: 1rem;
  display: flex ;
  font-family: 'Inter', sans-serif;
  margin-top: 32px ;
  gap : 0px ;
  overflow: hidden;
}
.supermarketoptionsdata{
  padding: 0.1rem;
  flex :1.5 ;
}

 
.link-style {
text-decoration: none; /* Remove underline */
color: inherit; /* Inherit text color from parent */
}


// top location and date section
.superMarketsIn{
  font-size: 32px ; 
  line-height: 36px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color:#1a212b; 
  display: inline-block;
}
.brwoseshoppagetext{
  display: flex;
  flex-direction: row;
  gap : 0.5rem ;
}

.london{
font-size: 32px ;
line-height: 36px;
font-weight: 600;
font-family: 'Inter', sans-serif;
color: #09a7b3;
display: inline-block;
}
.results{
  font-size: 16px ;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  color: #728197;
  text-align: left;
  display: flex;
  align-items: center;
  }
   #option{
      height: 45px ;
      border-radius: 8px ;
      margin-top : 40px ;
      width : 140px !important ;
      background-color:rgb(255, 255, 255);
      border: 1px solid #cbd4e1;
       padding: 0.3rem;
       font-family: 'Inter', sans-serif;
        color :#1a212b;
   }
   .options{
    display: flex ;
    gap : 25px ;
   // padding-left: 2rem;
    border-radius: 5px ;
    font-size: 13px;
    } 
  
// top dropdowns 
 .img_data_desc{
  display: flex;
  flex-direction: row;
  gap : 2rem ;
 }
.descriptions{

  display: flex;
  justify-content: space-between;
  background-color:rgb(255, 255, 255);
  border: 1px solid #cbd4e1;
  border-radius: 10px ;
  width : 100% !important ;
  height : 180px !important ;
}


.store_details_section{
  padding-top: 1rem;
}

.browsestoredata{
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
}

.imagestore1{
  width : 220px ;
  border-top-left-radius: 10px; /* Adjust the value as needed */
  border-bottom-left-radius: 10px;
  height: 180px !important ;
}

.dataparent{
  display: flex ;
  gap : 10px ;
  color: #1A212B ;
  font-weight :400 !important;
  font-size:  10px ;
  line-height: 8px ;  

}
.ratelight{
   font-family: "Inter",sans-serif;
   font-weight: 400  !important;
   font-size: 12px !important;
   line-height: 24px  !important;
   color :#1A212B  !important;
}
.commonfriend{
//  background-color:rgb(255, 255, 255);
  border: 1px ;
  background :#DBF1BC ; 
  border-radius: 6px ;


}
.browserdata_area_rate{
  display: flex;
  flex-direction: column;
  padding: 1rem ;
  justify-content: space-between;
}
.bestseller{
 // background-color:rgb(255, 255, 255);
  border: 1px ; 
  border-radius: 6px ;
  background : #C0D3F9 ;
}

.commonfrienddata{
  margin: 12px ;
  gap : -10px ;
  
  font-family: 'Inter', sans-serif;

}
.bestsellerdata{

  margin: 12px ;
  font-family: 'Inter', sans-serif;
 

}
.shopname{
  font-size: 18px ;
  color:  #1a212b;
  margin-top: 12px ;
  font-weight: 600;
  font-family: 'Inter', sans-serif;

}

.area{
  color :#728197; 
  font-size: 12px ;
  margin-top : 17px ;
}
.pricerating{
  display: flex;
 flex-direction: row;
 align-items: self-end;
}
.dataparent_store_area{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.startsfrom{
  font-size: 10px  ; 
  color:  #728197;

}
.location_slp{
  display: flex ;
  gap: 0.5rem ;
}
.addresss{
  font-size: 10px ;
  margin-bottom: 30px;
  margin-top: 4px;
  color: black;
}
.mappin{
 height: 18px  ;

}

  .weightrate{
      font-weight: 600;
      font-family: "Inter",sans-serif;   
font-weight: 600;
font-size: 20px ;
color  : #1A212B ;
line-height : 24px ;
text-align: right;  

  }

 
  .rate{
      display: flex ;
      flex-direction: row;
      gap: 5px ;
  }
  .rateflex{
      display: flex ;
      flex-direction: row;
      gap: 5px ;

  }

  .smpgraph {
    flex: 1;
   
    background-color: rgb(255, 255, 255);
    border: 1px solid #ffffff;
  
    height:1250px ;
  // z-index: -1;
   width: 500px !important;
   transform: scale(0.9);
    overflow: hidden;
    display: flex; /* Make it a flex container */
    align-items: stretch; /* Stretch the child to fill the container */
  }

  
  /* Optionally, you can style the child element */
  .smpgraph > Mapp {
    flex: 1;
    width: 100%; /* Ensure the child takes 100% width of the parent */
    height: 100%; /* Ensure the child takes 100% height of the parent */
  }

//pagination n
.pagination {
  display: flex;
  justify-content: center;
  margin: 50px !important;
  margin-top: 50px !important;
 
}

.pagination span {
  margin: 0 5px;
  cursor: pointer;
}

.pagination span.active {
  color: #09a7b3;
  font-weight: bold;
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px;
 
}

.pagination span,
.pagination button {
  margin: 0 5px;
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
}

.pagination span.active {
  background-color: #09a7b3;
  color: #fff;
  border-color:#09a7b3;
}

.pagination button.prevButton,
.pagination button.nextButton {
  background-color:#09a7b3;
  color: #fff;
}

.pagination button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

 // changes new 
 .rating0{
  display: flex;
  gap :4px ;
  flex-direction: row;
  text-decoration: none ;
  font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 18px ;
color: #1A212B ;
}
.rating3{
  color: #728197;
  font-family: "Inter",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
}
.rating2{
 
  width: 100px  !important;
  display: flex;
  flex-direction: row;
}

/// slphead and bottom

.slphead{
  border-bottom: 1px solid #CBD4E1;  
  display: flex; 
  gap : 300px ;
  font-family: "Inter",sans-serif;
  margin-top: 30px  ;

 .locationloggedindropboxslp{
  z-index: 999;
 }

}
.slpheadlogo{
  margin-bottom: 24px ;
  height: 30px; 

}

.slpbottom{
  border-top: 1px  solid #CBD4E1;  
  height: 316px ;
}
.slpcalender{
background: rgb(255, 255, 255) ;
padding: 10px ;
width  : 250px ;
}

// 
/* For screens smaller than 600px (typical mobile phones) */

.supermarketoptions {
display: flex;
flex-direction: row;
/* Other flex properties */
}

 


.store_area{

background: #d2b4d2;
border-radius: 6px;
padding: 0.4rem;
font-size: 12px;
text-decoration: none;
color: #000000  ;

}
// wishlist and result  date

.result_date_wishlist{
display: flex;
justify-content: space-between;
}
// from here working on responsive designs



.browserdata_area_rate_mobile{
  display: none;
}
// mobile version
.store_area_mobile{
  display: none;
}
.pricerating_mobile{
display: none;
}


// opertaing city 
.operting_city{
  display: flex;
  position: absolute;
  flex-direction: column;
  border: 1px solid #CBD4E1 ;
  width: 200px;
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap : 1rem ;
  z-index: 100;
  background: white;
  cursor: pointer;

  transform-origin: top center;
  animation: rollDown 0.5s ease-in-out;
  box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.2); /* Darker shadow */
  
}
@keyframes rollDown {
  0% {
      opacity: 0;
      transform: scaleY(0);
  }
  100% {
      opacity: 1;
      transform: scaleY(1);
  }
}

.dropdown-arrow{
  display: inline;
  margin-left: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.dropdown-arrow.rotate {
  transform: rotate(90deg); /* Rotate the arrow icon smoothly */
}
.listed_cities_slp{
  font-weight: 600;
  color: black;
}
.active_city{
padding: 0.5rem;
color: #1A212B;
}
.active_city:hover{
 background-color: #09a7b3;
color: white;
 border-radius: 5px;
}

@media (max-width: 768px) and (min-width: 320px) {

.results{
  display: none;
}

#option{
  height: 30px ;
  border-radius: 8px ;
  margin-top : 40px ;
  padding : 0.1rem ;
  max-width:  6.5rem ;
  background-color:rgb(255, 255, 255);
 // border:  1px solid rgb(158, 156, 156) ;
  font-family: 'Inter', sans-serif;
  color :#1a212b;
}
.options{
display:  flex ;
gap : 4px ;
border-radius: 5px ;
font-size: 10px;
} 
.supermarketoptions{
  margin : 0.5rem  ;
   padding: 0.2rem;
  display: flex ;
  font-family: 'Inter', sans-serif;
  margin-top: 32px ;
  gap : 0px ;
  overflow: hidden;
}
.superMarketsIn{
  font-size: 20px;
}
.london{
  font-size: 20px;
}

.layerslp{
  align-items: center;
  justify-content: center;

}

.smpgraph {
  display: none;
}
.store_area_mobile{
  display: block;
  background: #d2b4d2;
border-radius: 6px;
padding: 0.5rem;
font-size: 12px;
text-decoration: none;
color: #000000  ;
}

.pricerating_mobile{
  display: block;
  }
.descriptions{
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Adjust the gap between items */
    background-color:rgb(255, 255, 255);
    border: 1px solid #cbd4e1 ;
    border-radius: 10px ;
    height: 430px !important; 
    width: 80vw ;


}
.browserdata_area_rate_mobile{
  display: block;
  display: flex;
  
}
.browserdata_area_rate{
display: none;

}

.img_data_desc{
  display: flex;
  flex-direction: column;
  gap : 0.1rem ;
 }

.browsestoredata{
  display: flex;
   font-size: 14px;
   padding: 0.5rem;
}


.dataparent{
    gap : 10px ;
 font-size:  12px ;
}
.bestsellerdata{
  margin: 12px ;
}
.shopname{
  font-size: 20px ;
}
.line{
  border-top: 1px  solid #cbd4e1; 
  width : 360px 
}
.pricerating{
  gap : 120px ;
}
.startsfrom{
  font-size: 12px  ; 
}
.slpcalender{
  display: none;
  }
  .slphead{
    gap : 230px ;
  }
  .slpheadlogo{
    margin-bottom: 24px ;
    height: 30px; 
  
  }
  
  .imagestore1{
    width:  100% ;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 10px; /* Adjust the value as needed */
    border-top-right-radius: 10px;
    height: 250px !important ;
  }

// wastage scss as of now 





.ratelight{
  font-family: "Inter",sans-serif;
  font-weight: 400  !important;
  font-size: 16px !important;
  line-height: 24px  !important;
  color :#1A212B  !important;
}
.mappin{
  
}

    .weightrate{
        font-weight: 600;
        font-family: "Inter",sans-serif;   
  font-weight: 600;
font-size: 18px ;
color  : #1A212B ;
line-height : 24px ;
  text-align: right;  

    }

   
    .rate{
        display: flex ;
        flex-direction: row;
        gap: 5px ;
    }
    .rateflex{
        display: flex ;
        flex-direction: row;
        gap: 5px ;

    }

   // changes new 
   .rating0{
    display: flex;
    gap :4px ;
    flex-direction: row;
    text-decoration: none ;
    font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px ;
  color: #1A212B ;
  }
  .rating3{
    color: #728197;
  
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
  }
  .rating2{
   
    width: 100px  !important;
    display: flex;
    flex-direction: row;
  }

  /// slphead and bottom
  





  
 
  .slpbottom{
    border-top: 1px  solid #CBD4E1;  
    height: 316px ;
  }



}