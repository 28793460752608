.listing_dashboard{
    display: flex;
    justify-content: top;
    align-items: top;
    padding: 2rem ;
    flex-direction: row;
    gap : 2rem ;
}
.dashboard_listing_container{
    font-size: 0.9rem;
    border:  1px solid #ECEEF6;
    width: 15vw ;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
     background: #ffffff;
    padding: 1rem;
    gap : 0.5rem ;
}
.listing_dashboard_headline ,.listing_dashboard_data{
    font-family: "Poppins" ,sans-serif;
    font-weight: bolder;
    
}
.listing_dashboard_data{
font-size: 0.9rem;
}
.listing_dashboard_subheadline{
    color : #949494 ;
    font-size: 0.7rem;
}
.listing_dashboard_headline_graph{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.graph_dimm{
    width: 4vw ;
    height: 4vh ;
}
// super analytics 

.super_analytics_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 89vh;
    width: 100%;
    position: relative; /* Ensure the container is the reference for absolute positioning */
}



.glassy_rectangle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75vw;
    height: 75vh;
    background: rgba(57, 193, 234, 0.2);
    border-radius: 16px;
   // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(57, 193, 234, 0.3);
    position: relative; /* Position the glassy rectangle over the image */
    z-index: 2; /* Set above the image */
}
.Img_analytical{
    height: 75vh;
    width: 75vw;
    border-radius: 16px;
    object-fit: cover; /* The image will cover the container */
    position: absolute; /* Fill the entire container */
}
.text_over_glassy{
 text-align: center;
 font-weight: 900; /* Extra bold */
 font-size: 3rem; /* Adjust size as needed */
 background: linear-gradient(45deg, #cbeaf0, #1e9fc2 ,  #1b9189  ,#035065 ); /* Gradient colors */
 -webkit-background-clip: text; /* Clip the gradient to the text */
 -webkit-text-fill-color: transparent; /* Make text color transparent to show gradient */
 background-clip: text; /* Clip the gradient to the text for non-WebKit browsers */
 color: transparent; /* Make the text color transparent */

}

@media (max-width: 768px) {
    .glassy_rectangle {
        width: 95%;
        padding: 15px;
    }
}


@media (max-width: 768px) and (min-width: 320px) {
    .listing_dashboard{
        display: flex;
        flex-direction: column;
        justify-content: top;
        align-items: top;
        padding: 2rem ;
        gap : 2rem ;
    }
    .dashboard_listing_container{
        font-size: 0.9rem;
        border:  1px solid #ECEEF6;
        width: 75vw ;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
         background: #ffffff;
        padding: 1rem;
        gap : 0.5rem ;
    }
    .graph_dimm{
        width: 8vw ;
        height: 4vh ;
    }
    
.glassy_rectangle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75vw;
    height: 50vh;
    background: rgba(57, 193, 234, 0.2);
    border-radius: 16px;
   // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(57, 193, 234, 0.3);
    position: relative; /* Position the glassy rectangle over the image */
    z-index: 0; /* Set above the image */
}
.Img_analytical{
    height: 50vh;
    width: 75vw;
    border-radius: 16px;
    object-fit: cover; /* The image will cover the container */
    position: absolute; /* Fill the entire container */
}
.text_over_glassy{
 text-align: center;
 font-weight: 900; /* Extra bold */
 font-size: 2rem; /* Adjust size as needed */
 background: linear-gradient(45deg, #cbeaf0, #1e9fc2 ,  #1b9189  ,#035065 ); /* Gradient colors */
 -webkit-background-clip: text; /* Clip the gradient to the text */
 -webkit-text-fill-color: transparent; /* Make text color transparent to show gradient */
 background-clip: text; /* Clip the gradient to the text for non-WebKit browsers */
 color: transparent; /* Make the text color transparent */

}


}



//
.guide{
    text-decoration: none  ;
    color :#1e9fc2 ;
    font-weight: 600;
    
}
.guide_logo{
    height: 10px;
    width: 10px;
}