.omnichannelgroupParent{
    display: flex; 
    gap : 2rem ;
   margin-top: 5rem;
   padding: 5rem;
    background-color: #F6F8FB ;
    font-family: "Inter",sans-serif;
    box-sizing: border-box;
}
.omnicahnnelimg{
   width: 90%;
   height: 90%;
   box-sizing: border-box;

}
.omnichannelframeGroup{
    display: flex;
    flex-direction: column;
    gap  : 2rem ;
   
    }


    .omnichannelwereBuilding{
        color: #09a7b3;
        font-size: 1.5rem;
        line-height: 16px;
      
    }

.omnichannelaffordableOmnichannelExperie{
    font-size: 40px;
    font-weight: bold;
    align-items: center;
justify-content: center;
}
.omnichannelbutBeforeThat1{
color: #728197; 
font-size: 1.2rem;
}
.omnichannelbutton{
    display: flex;
    background: #09a7b3;
    max-width: 150px;
    border-radius: 10px;
    padding: 0.5rem;
    gap : 1rem ;
    cursor: pointer;
    transition: all 0.4s ease; 
    align-items: center;
    justify-content: center;
}
.omnichannelbutton:hover{

    transform: scale(1.1);
   
}

.omnichannellabel{
    text-decoration: none;
    color: #ffffff;   
}
.omnichannelicon2{
    align-items: center;
    padding-top: 0.1rem;
    justify-content: center;
}



@media (max-width: 768px) and (min-width: 320px) {
    .omnichannelgroupParent{
        flex-direction: column;
        padding: 2rem;
       background-color: #F6F8FB ;
    
    }
    .omnicahnnelimg{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
     
     }
     .omnichannelwereBuilding{
        color: #09a7b3;
        font-size: 1.2rem;
        line-height: 16px;
      
    }
    .omnichannelaffordableOmnichannelExperie{
        font-size: 1.6rem;
        font-weight: bold;
        align-items: center;
    justify-content: center;
    }
    .omnichannelframeGroup{
        display: flex;
        flex-direction: column;
        gap  : 2rem ;
       
        }
        .omnichannelbutBeforeThat1{
            color: #728197; 
            font-size: 0.8rem;
            }



        }