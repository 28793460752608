.textField {
   height: 44px;
  cursor: pointer;
  display: flex;
  background: #eceaea;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  padding-left:8px;
 
}

.calender {
  margin-bottom: 8px;
  margin-right: 2px;
  font-size: 30px;
}

.dateView
{	
  width: 300px;
  margin-left: 30px ;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter",sans-serif;
  color: #1a212b;
  text-align: left;
  display: inline-block; 
  
}


.focusedBorder
{
 
}

.mainContainer
{
    width:100%;
}


.calendericon{

}