.blogs_section {
    margin: 10vh;

    font-family: "Inter" ,sans-serif;
 
   
  }
  @media only screen and (max-width: 768px) {
    .blogs_section {
      padding: 1rem;
      margin: 1vh; /* Adjust margin for smaller screens */
     
    }
  }

.blog_1_img{
    width: 100% ;
    height : 80vh ;
    align-items: center;
    justify-content: center;
    border-radius: 10px ;
}
.blog_section_img{
    width: 100% ;
}
.blog_full_prev_button{
    color: #09a7b3;
}
.blog_writer_title{
    max-width: 300px;
   
}
ol,ul{
    display: flex;
    flex-direction: column;
    gap :1rem ;
    font-family: "Inter",sans-serif;
    color: #728197; 
    font-weight: 300;
}
.blog_writer_details{
    display: flex;
    gap  : 1rem ;
}
.blog_writer_img{
    width: 3rem ;
    height: 3rem ; 
    border-radius: 50% ;
}
.blog_writer_timestamp{
    width: 300px;
    color: #728197; 
}
.blog_full_headline{
    margin-top: 5rem ;
    font-size: 2.5rem ;
    font-weight: bold
}
.blog_full_paragraph_sections{
    display: flex;
  
    margin-top: 3rem ;
    flex-direction: column;
    gap : 1rem ;
}
.blog_section_para_sub_heading{
    font-size: 1.4rem ;
    font-weight: bold

}
.credit_blog_5_img{
    text-align: right;
    color: #728197;
    font-size: 0.7rem;
}
.blog_section_para , .reference_blog_5{

    color: #728197;
}

.blog_actionalble_tip{
    font-size: 1.1rem ;
    font-weight: 500;  
    color: black;
      
}
.blog_writer_section_details{
    display: flex;
    margin-top: 3rem ;
    gap: 48.5% ;
    
}
.info-sub-text{
    text-decoration: none;
}
.blog_writer_accounts{
    display: flex;
    gap : 30% ;
   
}
.blog_writer_timestamp_title{
    display: flex;
    flex-direction: column; 
    gap : 0.5rem ;
}

.Hyperlink_blogs{
    color : #09a7b3 ;
    font-weight: 600;
}
dl ,li {
    font-weight: 500;
}
li{
    color: #728197;
}
//responsive

@media only screen and (max-width: 768px) {
    .blog_writer_section_details{
        display: flex;
      //  margin-top: 3rem ;
        gap : 2rem ;
         flex-direction: column;
        
    }
    .blog_writer_accounts{
        display: flex;
        gap : 1rem ;
       
    }
    .blog_writer_title{
        max-width: 250px;
       
    }
    .blog_writer_timestamp{
        font-size: 0.9rem;
        max-width: 250px;
        color: #728197; 
    }
    .blog_1_img{
        width: 100% ;
        height : 50vh ;
        align-items: center;
        justify-content: center;
        border-radius: 10px ;
    }
    .blog_full_headline{
        margin-top: 3rem ;
        font-size: 2rem ;
        font-weight: bold
    }
  }

