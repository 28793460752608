.blogs_containers {
  
 padding: 2rem;
 padding-top: 3rem;
  font-family: "Inter" ,sans-serif;
   
  }
  .headline_section_blogs{
    padding: 2rem;
  }
  .blogs_headtag_blur{
    display: flex;
    padding: 1rem;
 
  background:  #09a7b3 ;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  }
  .blogs_head_text{
    text-align: left;
    color: white;

  }
  .blog_link_head{
    text-align : right ;
  }





  .blogs-container {

    margin-top: 2rem ;
    cursor: pointer;
    display: flex;
    flex-direction: column;
   // align-items: center; /* Center align items horizontally */
  }

  .blog-item {
    display: flex;
    flex-direction: row; /* Display each blog item as a column */
    //width:  70vh ;
   // width: 100% ; 
    gap : 3rem ; 
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 2rem;
    border-radius: 10px;
  }

  
  .blog-item:hover{
    background: #edebeb;

  } 
  .blog_img{
   width:30vw;
    border-radius: 5px;
    height: 40vh ;
  }
.blog_underline{
  margin-top: 3rem;
  border-top: 1px solid #cbd4e1 ;
}

  .blog_full_read{
    margin-top: 2rem;
    font-weight: 600;
    font-size: 0.8rem;
    color: #09a7b3;
  }
  .blog-content {
  
    margin-top: 5px;
  }
  .social-fb-third-retail{
    text-decoration: none;
    color: white;
  }
  .blog-headline {
    width: 80vh;
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .blog-desc {
    margin-top: 2rem;
    font-size: 1rem;
  }
  
  .blog-date {
   
    font-size: 1rem;
  }
  .subscribe_section_newsletter{
    text-align: center;
margin-bottom: 5rem;
    margin-top: 5rem ;
    display: flex;
    flex-direction: column;
    gap :1rem  ;
  
  }
  .subscribe_head{
    font-size: 1.5rem ;
    font-weight: 900 ;
  }
.subscribe_sub_heading{
  color : #707070 ; 
}
.subscribe_email_button{
  margin-top: 2rem ;
  align-items: center;
  gap : 2rem ;
  justify-content: center;
  display: flex; 
  flex-direction: row ;
 }
 .subscribe_button{
  background: #09a7b3; 
  border-radius: 5px;
  padding: 0.5rem ;
  color: white;
  font-size: 15px;
  cursor: pointer;
 }
 #email{
 padding-left: 20px;
  border: 1px solid #ccc;
  border-radius: 5px ;
  width : 16.5rem  ;
  height: 2rem ;

 }
 
 #email:focus {
  border: 1px solid #ccc;
}
.input_subscribe_field input[type="email"]:focus {
  outline: none; /* Remove default focus outline */
  border-color: #ccc; /* Override border color on focus to remain unchanged */
}
.Link{
  text-decoration: none ;
  color : black 
}
  /* Responsive CSS */
  @media only screen and (max-width: 768px) {
    .blogs_containers{
      padding: 0;
   
     
    }
    .subscribe_email_button{
      margin-top: 1rem ;
      align-items: center;
      gap : 1rem ;

      justify-content: center;
      display: flex; 
      flex-direction: column ;
     }
     .subscribe_button{
      background: #09a7b3; 
      border-radius: 5px;
      width: 70%;
      padding: 0.5rem ;
      color: white;
      font-size: 15px;
      cursor: pointer;
     }
     .blog-item {
      display: flex;
      flex-direction: column; /* Display each blog item as a column */
      //width:  70vh ;
     // width: 100% ; 
      gap : 3rem ; 
     // margin-top: 20px;
      margin-bottom: 20px;
      padding: 1rem;
      border-radius: 10px;
    }
    
    .blog-item:hover{
      background: #edebeb;
  
    } 
    .blog-headline {
      width: 90vw;
      margin-top: 2rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
    .headline_section_blogs{
      padding: 1rem;
      padding-top: 3rem;

    }
    .blog_img{
      width:92.5vw;
      border-radius: 5px;
      height: 40vh ;
    }
    .blogs_headtag_blur{
      display: flex;
      flex-direction: column;
  padding: 0.5rem ; 
 
    background:  #09a7b3 ;
    border-radius: 10px;
    gap : 1rem ;
    align-items: center;
    justify-content: space-between;
    }
    .blogs_head_text{
      text-align: left;
      font-size: 0.7rem;
      color: white;
  
    }
    .blog_link_head{
      text-align : right ;
      
      font-size: 0.8rem;
    }
    .blog-desc {
      margin-top: 2rem;
      font-size: 1rem;
      width: 90vw;
    }
    .blog-date {
      width: 80vw;
      font-size: 1rem;
    }
    .blog-content {
      width: 80vw;
      margin-top: 5px;
    }
    
  }