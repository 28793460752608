

// new css of self details 
// border: 1px dashed #9aa8bc;


.selfform1{
    font-family: "Inter",sans-serif;
    margin-bottom: 3rem;
}
input{
   // padding-left: 0.5rem;
}
.selfform{
  // width: 100vw;
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
    gap : 4rem ;
    align-items: center;
    justify-content: center;
       
    }
    .selfdetailslist,
    .selfimgagebox
       {
        display: flex;
       width: 55vw;
        flex-direction: column;
        border-radius: 12px;
        background-color:#fff; 
        border: 1px solid #9aa8bc;
        box-sizing: border-box;
      
    }
    .selfdetailhead,
    .selfimgbox{
        border-bottom: 1px solid #cbd4e1; 
    }
    .selfinfoheadbox ,
    .shelf_image_header{
display: flex;
flex-direction: column;
gap : 0.5rem ;
padding: 1rem;
}
.selfinfo ,
.selfimgehead{
    font-size:  16px;
    font-weight: 600 ;
    color: #1a212b;
}
.selflistdesc ,
.selfimageheaddesc{
    font-size: 12px ;
    font-weight: 400;
    align-self: stretch;
    line-height: 18px  
}
.selflistdata{
    padding: 1rem;
    display: flex;
    flex-direction: column;
}
.selfdataname{

}

.areaandname1,
.areaandname2,
.selfdatadesc{
    display: flex;
    gap : 0rem ;
    flex: 2;
 flex-direction: column;
  }
  .selftyperent{
    display: flex;
    gap : 1rem ;
    flex: 2;

  }

  #selfinputratefield{
    flex: 2;
    width: 98%;
    padding: 1%;
    border-radius: 5px ;
    height: 32px ;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
 
   
}

#selfinputratefield::placeholder{
    font-size: 12px;
}



#selfdescfield{
    flex: 2;
    width: 96%;
    border-radius: 5px ;
    height: 60px ;
    padding: 2%;
    font-style: "Inter",sans-serif;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
 
}#selfdescfield:focus {
  //  border-color: #ccc; /* Keeps the same border color on focus */
    outline: none; /* Removes any default outline */
  }
  #selfdescfield::placeholder {
    font-size: 12px; /* Adjust placeholder font size */
    color: #888; /* Optional: Change placeholder color */
  }
#selftypefield{
    flex: 1;
    width: 100%;
    border-radius: 5px ;
    height: 40px ;
    background-color:#fff; 
    border: 1px solid #9aa8bc;
}
.selfdatatype{
    flex: 0.8;
    width: 100%;
}
.selfrateperday{
    flex: 1.2;
    width: 100%;
} 
.selfdesc1,
.selfname1,
.selftype1,
.selfdatarate{
    color: #728197;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 0.5rem;
} 
.know_calculate{
    color: #728197;
    font-size: 10px;
} 
.logo-button{
    font-size: 13px;
    background :  #09a7b3; 
    width: 5rem;
    color: white;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    padding: 0.2rem;
    cursor: pointer;
}

// Image section 
.selfimgbox{
    
}

.selfimgagebox{

}
.input_image_section{
    display: flex;

    flex-direction: column;
    gap:  2rem ;
    padding: 1rem;
}
.selfuploadbox2{
    cursor: pointer;
    border : 1px dashed  #9aa8bc;
    border-radius: 5px;
    display: flex;
    padding: 3rem;
    gap : 1rem ;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.upload_shelf_image_save_next{
    display: flex;
    justify-self: center;
    align-items: center;
    gap: 1rem ;
    flex-direction: column;
}
.uploadimage_save_next_button{
     display: flex;
     flex: 2;
     gap: 2rem ;
     width: 100%;
     
}
#button_shelf_info{
    text-decoration: none;
    border-radius: 8px;
    background-color: #09a7b3;
    cursor: pointer;
    border: none;
    padding: 12px 22px;
    font-size: 14px;
    color: #fff;
    width: 100%;
}
.button_next_shelf_info{
    flex: 1;
    
}
.summitandsaveself{
   
    text-decoration: none;
    border-radius: 8px;
    background-color: #09a7b3;

    cursor: pointer;
   border: none;
 display: flex;
 align-items: center;
 text-align: center;
 justify-content: center;
    padding: 12px 22px;
    font-size: 14px;
    color: #fff;
    width: 87%;
}
#Link{
    text-decoration: none;
}
.shelf_info_save_button{
    flex: 1;
}
.statusofimageload,
.progress_percentage{
    font-size: 14px;
    margin-top: 0.5rem;
   
}
@media (max-width: 768px) and (min-width: 320px) { 

    .selftyperent{
        display: flex;
        flex-direction:column ;
        gap : 0.5rem ;
        flex: 2;
    
      }
      .selfdatatype{
        flex: 1;
        width: 100%;
    }
    .selfrateperday{
        flex: 1;
        width: 100%;
    }  
    .selfdetailslist,
    .selfimgagebox{
        display: flex;
        width: 100%;
        flex-direction: column;
        border-radius: 12px;
        background-color:#fff; 
        border: 1px solid #9aa8bc;
        box-sizing: border-box;
      
    }
    .selfform{
         width: 91vw;
         display: flex;
        
         padding: 1rem;
         padding-top: 3rem;
         flex-direction: column;
         align-items: center;
         justify-content: center;
            
         }.selfdesc1,
         .selfname1,
         .selftype1,
         .selfdatarate{
             color: #728197;
             font-size: 0.8rem;
             font-weight: 400;
         } 
         // Image section 

    .selfuploaddesc{
        font-size: 12px;
        font-weight: 500;
    }
    .statusofimageload,
    .uploadinstruction{
        font-size: 12px;
        margin-top: 0.5rem;
       
    }
    .uploadimage_save_next_button{
        display: flex;
        flex-direction: column;
        flex: 2;
        gap: 1rem ;
        width: 100%;
        
   }
   .summitandsaveself{
   
    text-decoration: none;
    border-radius: 8px;
    background-color: #09a7b3;

    cursor: pointer;
   border: none;
 display: flex;
 align-items: center;
 text-align: center;
 justify-content: center;
    padding: 12px 22px;
    font-size: 14px;
    color: #fff;
    width: 85%;
}

}






























