.Dashboard_ecomm{
    font-family: "Inter",sans-serif;
    display: flex;
  
    flex : 10 ;
}

.left_ecomm_upper{
    display: flex;
    flex-direction: column;
    gap : 1rem ;
    padding: 1rem;
  
  
}
.left_ecomm_bottom{
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap : 1rem ;


}
.left_ecomm{
  display: flex;
  flex-direction: column;
  flex: 1.5;
  border-right: 1px solid #CBD4E1;

   height: 100vh;
 
 
  font-size: .8rem;
 
 justify-content: space-between;
}

.mybooking_headline{
    font-size: 1.4em;
    font-weight: 600;
}



.right_ecomm{
 // 
 // margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap : 1.6rem ;
    background-color: #F6F8FB ;
    flex: 7.5;
  
}
.data_section_dashboard{
  padding: 2rem;
}
.mobile_size_logo{
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #CBD4E1;
}
.hum_menu_ecomm{

}

.left_ecomm_option_icon{
  width: 15px;
  height: 15px;
}
.left_ecomm_options{
    display: flex;
    gap : 1em ;
    font-size: 0.7rem;
    align-items: center;
    padding: 0.2rem; 
}
.left_ecomm_options:hover {
    background-color:  #09a7b3;   
    border-radius: 0.4rem;
    cursor: pointer;
}

.upcoming_booking_table{
    font-size: 0.7rem;
    cursor: pointer;
}
.Cancelled_ecomm_booking{
    font-size: 0.7rem;
    cursor: pointer;
}

.ecomm_past_booking {
    font-size: 0.7rem;
    cursor: pointer;
  
  }
// table csss 

table {
    width: 100%;
    font-size: 0.7rem ;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #ddd;
    border-radius: 8px ;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color:#F6F8FB;
  
  }
  
  thead th:first-child {
    border-top-left-radius: 8px;
  }
  
  thead th:last-child {
    border-top-right-radius: 8px;
  }
  
  tbody tr:nth-child(even) {
    background-color: #F6F8FB;
  }
  
 
  
  tbody tr:last-child {
    border-bottom: none;
  }
  

  
  .pagination-cell {
    text-align: center;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination_dashboard button {
    margin: 0 2px;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  
  .pagination_dashboard button.active {
    background-color: #4CAF50;
    color: white;
  }
  .icon_to_view_cancel{
    display: flex;
    gap : 5px ;
  }
 
   // satus color 
    .status-confirmed {
        color: green;
      }
      
      .status-pending {
        color: rgb(159, 95, 21);
      }
 
  // view box 
  .view_shelf_detail{
    width: 70%;
    border : 1px solid #CBD4E1 ;
    border-radius: 12px ;
    padding: 1.5rem ;
    display: flex;
    flex-direction: column;
    gap : 2rem ; 
    border-radius: 10px ;
   
  }

  .view_container{
    display: flex;
    flex-direction: row;
   
    justify-content: space-between;

  }
  .view_headline{
    font-size: 1.2rem ;
    font-weight: 600;
  }
  .cross_close{
    cursor: pointer;
  }
  .container_details{
   
    border : 1px solid #CBD4E1 ;
    border-radius: 12px ;

 display: flex;
 gap : 0rem ; 
  }
  .shelf_view{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .detail_desc{
    display: flex;
    font-size: 0.8rem;
    flex-direction: column;
     justify-content: space-around;
    padding: 1.5rem ;
  }
  .booking_id{
    background: #df8da0;
    border-radius: 10px;
    padding: 0.5rem ;
  }
  .shop_name{
    font-size: 1.2rem ;
    font-weight: 500;
  }

  .date_no_of_shelf{
    border-top: 1px solid #CBD4E1;
    
    color : #728197
  }

  /* App.css */

/* Modal styles */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    z-index: 1000; /* Ensure modal is on top of other content */
    
  }
  
  .modal-content {
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 80%; /* Adjust width as needed */
    max-width: 600px; /* Maximum width of the modal */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow for the modal */
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .modal-close {
    cursor: pointer;
  }
  
  /* Backdrop for the modal */
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    z-index: 999; /* Ensure backdrop is behind the modal */
  }
  // ecoomm main page css
  .Dashboard_ecomm_header{
    display: flex;
    flex-direction: column;

    gap : 2rem ;
}
.mybooking_section{
  display: flex;
  gap : 3rem ;
  font-size: .8rem ;

}
.my_bookings{
  display: flex;
  flex-direction: column;
  gap: 1rem ;
}

.tab {
  position: relative;
  padding: 10px;
  cursor: pointer;
}

.tab::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color:  #09a7b3;   
  transition: width 0.3s;
}

.tab:hover::after,
.tab.active::after {
  width: 100%;
}
// dashboard sections 
.dashboard_ecomm_center{
  //flex-direction: column;
  display: flex;
  gap : 2rem ;
}
.dashborad_container_section_1{
  border: 1px solid #ECEEF6 ;
  padding: 1rem ;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap : 0.8rem ;
  border-radius: 10px ;
  font-size: .7rem ;
  font-weight: 600 ;
}
.dashboard_subheadline{
  font-size: .6rem ;
  font-family: "Poppins" , sans-serif;
  font-weight: 500 ;
  color: #949494;
}
.dashboard_value_proposition{
  font-size: .9rem ;
  font-weight: 900 ;
}
.dashboard_section_headline_and_growth{
  display: flex;
  align-items: center;
  justify-self: center;
  gap : 2rem ;
}
.growth_graph{
  width: 40px;
  height : 20px 
}
//profile page
.profile_section{
  display: flex;
  gap : 3rem ;
  font-size: .8rem ;
  font-weight: 600;

}
.profile_container{
  display: flex;
  flex-direction: column;
  gap: 1rem ;
  padding-left: 1rem;
   
}
.profile_container_main{
  display: flex;
  flex-direction: column;
 // padding-left: 1rem;
  gap: 1.5rem;
}

.tab {
  position: relative;
  padding: 10px;
  cursor: pointer;
}

.tab::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color:  #09a7b3;   
  transition: width 0.3s;
}

.tab:hover::after,
.tab.active::after {
  width: 100%;
}

// genral css
.profile_data_ecomm_headline_placeholder{
  display: flex;
  flex-direction: column;
  gap : 1rem ;
}
.profile_text_ecomm{
  color: #728197 ;
  font-size: 0.8rem;
  font-weight: 500;
  align-items: left ;
}
.edit_button_ecomm_general{
  display: flex;
  width : 5rem ;
  flex-direction:row;
cursor: pointer;
  border : 2px solid #09a7b3 ;
  border-radius: 10px ;
justify-content: center;
  gap : 0.2rem ;
  padding-top: 0.25rem;
}
.edit_button_text_ecomm{
  color: #09a7b3;
  align-items: center;
  margin-top: 3px;
  justify-content: center;
}
.profile_placeholder_ecomm{

 //border : 1px solid rebeccapurple ;
 
}
.placeholder_general_ecomm{
  background: #ffffff;
  border-radius: 5px ;
  height : 20px ;
  width: 30vw;
  padding: 10px;
  border: none;
}
input:focus {

  outline: none; /* Remove default outline */
}
//notification
.notification_sections{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border : 1px solid  rgb(234, 241, 246) ;
  padding: 0.5rem ;
  background: white;
  font-weight: 500;
  border-radius: 5px ;
  align-items: center;
  color: #1A212B;
  font-size: 0.8rem;
}

.notification_edits{
  display: flex;
  flex-direction: row;
  gap : 1rem ;

}.notification_status{
  align-items: center;
  display: flex;
  flex-direction: row;
  gap : 0.5rem ;
  justify-content: center;
}
.notification_edit{
border-radius: 5px;
cursor: pointer;
 background-color: #ECEFF4;
 padding: 0.3rem ;
}


//privacy
.privacy_container_ecomm{
  display: flex;
  flex-direction: row;
    border : 1px solid  rgb(234, 241, 246) ;
    padding: 0.7rem ;
    background-color: white;
    justify-content: space-between;
    border-radius: 5px ;
    align-items: center;
  color: #1A212B;
  //width: 60vw ;
  padding-left: 1rem ;
  font-size: 0.8rem;
}
.privacy_section_ecomm{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap : 1rem ;

}
.password_change_text_ecomm_headline{
  font-weight: 600;
  color: #001325 ;
}
.password_change_text_ecomm_subhedline{
  color : #728197 ;
  font-size: 0.6rem ;
}
.lock_password_icon_ecomm{
  background-color: #F5F5F5;
  border-radius: 100% ;
  padding: 0.4rem ;
}

// overlay diaglog box
.change_password_ecomm_button{
   background-color: #09a7b3;
   border-radius: 5px;
   color: #ffff;
   cursor: pointer;
   display: inline-block;
   padding: 0.5rem ;
}

.overlay_ecomm{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog_ecomm {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dialog_ecomm h2 {
  margin-top: 0;
}


.headline_dialog_ecomm{
  color: #1A212B;
  font-weight: 600;
  font-size: 0.9rem;
}
.existing_password_ecomm{
  font-weight: 400;
  color: #728197 ;
  font-size: 0.7rem;
}
.dialog_ecomm{
  display: flex;
  flex-direction: column;
  gap : 1rem ;
}
.existing_password_ecomm_head_placeholder, .confirm_password_ecomm_head_placeholder,.new_password_ecomm_head_placeholder {
  display: flex;
  flex-direction: column;
  gap : 0.3rem ;  
}
.bottom_section_buttons_ecomm{
  display: flex;
  flex-direction: row;
  gap : 1rem ;
  align-items: right;
  justify-content: right;
}
.cancel_ecomm_privacy{
background-color: #ECEFF4;
color: #1A212B;
padding: 0.5rem;
cursor: pointer;
border-radius: 5px;
}
.update_button_password_ecomm{
color: white;
cursor: pointer;
background-color: #09a7b3;
padding: 0.5rem ;
border-radius: 5px;
}
.placeholder_general_ecomm_privacy{
  background: #F6F8FB;
  border-radius: 5px ;
  height : 20px ;
 width: 92%;
 padding: 10px;
  border: none;
}

// invite css
.invite_ecomm_container{
  display: flex;
 margin-top: 20vh;
 margin-left: 10vw;
 max-width: 50vw ;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to the table */
  flex-direction: column;
  padding: 2rem ;
  gap : 2rem ;
  border-radius: 10px;
}
//table 
.table_invites_headline{
  display: flex;

  flex-direction: column;
  gap : 1rem ;
  border : 1px solid #E4E7EC ;

  border-radius: 5px ;
}
.table_invites_list_container{
  display: flex ;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.5rem;
  padding-left:  1rem ;
  padding-right: 1rem ;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #9AA8BC ;
  justify-content: space-between;
}
.table_invite_data_user_mail_img{
  display: flex;

  flex-direction: row;
  text-align: center;
  //justify-content: center;
  gap : 0.5rem ;
}
.invite_role{
  color : #728197 ; 
}
.table_invites_data{
  display: flex;
 
  padding-left:  1rem ;
  padding-right: 1rem ;
  padding-bottom: 0.5rem ;
  flex-direction: row;
  font-size: 0.8rem;
justify-content: space-between;
}
.invite_headline_ecomm{
  display: flex;
  flex-direction: column;
  gap: 0.5rem ;
}
.invite_email_input_ecomm{
  display: flex;
  gap: 2rem ;
  flex: 10;
}
.invite_email_input_ecomm_placeholder{
flex: 8;
}
.placeholder_general_ecomm_invite{
  width: 100%;
  height: 1.8rem ;
  outline: none;
  padding-left: 0.5rem ;
  border-radius: 5px ;
  border: 1px  solid  #CBD4E1;
}
.placeholder_general_ecomm_invite:focus {
 
  outline: none; /* Remove outline */
}

.invite_email_input_ecomm_send_invite {
  flex: 2;
  background-color: #09a7b3;
  padding: 0.5rem ;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
.invite__main_headline_ecomm{
  font-size: 1rem;
  font-weight: 600;
  color: #1A212B;
}

// Responsive Designs'''


//sidebar
/* Styling for the hamburger toggle button */

/* Default Sidebar Styling (for larger screens) */
.toggle-icon_ecomm_dashboard{
  width: 30px;
  height: 30px;
}
.left_ecomm{
  display: flex;
  flex-direction: column;
  flex: 1.5;
  transition: 0.3s ease; /* Smooth transition */
  border-right: 1px solid #CBD4E1;
  z-index: 999; /* Ensure it stays on top */
   height: 100vh;
 
 
  font-size: .8rem;
 
 justify-content: space-between;
}

/* Sidebar content styling */
.sidebar-content {
  padding: 20px;
}

.sidebar-content ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-content ul li {
  padding: 10px 0;
  cursor: pointer;
}

.sidebar-content ul li:hover {
  background-color: #575757;
}

/* Mobile View: Sidebar hidden by default (closed) */
.left_ecomm.closed {
  left: -250px; /* Move off-screen to the left */
  transition: 0.3s ease; /* Smooth transition */
}

/* On larger screens (desktop, laptops) the sidebar is always visible */
@media (min-width: 769px) {
  .left_ecomm {
    left: 0; /* Sidebar is visible */
  }

  .toggle-icon ,.mobile_size_logo{
    display: none; /* Hide the hamburger icon on larger screens */
  }
}




@media (max-width: 768px) and (min-width: 320px) {
  .invite_email_input_ecomm_send_invite {
    flex: 2;
    width: 100%;
    background-color: #09a7b3;
    padding: 0.5rem ;
    text-align: center;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  .profile_data_ecomm_headline_placeholder{
    display: flex;
    flex-direction: column;
    gap :0.5rem ;
  }
  .right_ecomm{
    display: flex;
    flex-direction: column;
    gap : 1.6rem ;
    flex: 8.6;
   
}
.dashboard_ecomm_center{
  flex-direction: column;
  display: flex;
  gap : 1rem ;
}
.left_ecomm {
  position: fixed;
  top: 0;
  background: white;
  left: 0;
  width: 200px;
  height: 100%;
}
//invite section 
.invite_ecomm_container{
  display: flex;
 margin-top: 0px;
 margin-left: 0px;
  max-width : 100vw ;
 // height: 100vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to the table */
  flex-direction: column;
  padding: 0.5rem ;
  gap : 1rem ;
  border-radius: 10px;
}
.toggle_button_notification_ecomm{
  height: 30px;
  width: 30px;
}
.invite_headline_ecomm{
  gap: 0.3rem ;
}
.invite_subheadline_ecomm{
  color : #728197 ;
  font-size: 0.7rem;
}
.data_section_dashboard{
  height: 100vh;
  padding: 1rem;
}
.edit_button_ecomm_general{
  display: flex;
  width : 5rem ;
  margin-top: 1rem;
  flex-direction:row;
cursor: pointer;
  border : 2px solid #09a7b3 ;
  border-radius: 10px ;
justify-content: center;
  gap : 0.2rem ;
  padding-top: 0.25rem;
}
.invite_email_input_ecomm{
  display: flex;
  gap: 1rem ;
  flex: 10;
}
.invite_email_input_ecomm_placeholder{
  flex: 8;
  }
  .placeholder_general_ecomm{
    background: #ffffff;
    border-radius: 5px ;
    height : 20px ;
    width: 80%;
    padding: 10px;
    border: none;
  }

  .placeholder_general_ecomm_invite{
   padding-left: 0.3rem ;
  }
  //profile
  .profile_container{
    display: flex;
    flex-direction: column;
    gap: 0.3rem ;
     
  }
  .profile_text_ecomm{
    font-size: 0.7rem;
 
  }
  .notification_sections{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
      border : 1px solid  rgb(234, 241, 246) ;
      padding: 0.3rem ;
      font-weight: 500;
      border-radius: 5px ;
  align-items: center;
 
    color: #1A212B;
    font-size: 0.5rem;
  }
  .lock_password_icon_ecomm{
    background-color: #F5F5F5;
    border-radius: 100% ;

    padding: 0.4rem ;
  }
  .lock_icon_privacy_ecomm{
    height: 20px;
    width: 20px;
  }
  .password_change_text_ecomm_subhedline{
    color : #728197 ;
    font-size: 0.5rem ;
  }
  .data_section_my_bookings{
   // width: 60vw;
  }
  .mybooking_section{
    display: flex;
    gap : 1rem ;
    font-size: .5rem ;
  
  }
  .mybooking_headline{
    font-size: 1rem;
    font-weight: 600;
}
.booking_logo_ecomm{
width: 20px; 
height: 20px;
}

  
  
}

