.fullcal{
    display: flex;
    flex-direction: column ;
    gap : 0.5rem ;
    border: 1px solid #ccc;
    border-radius: 10px ;
    max-width: 700px;
}
.calculator_headline{
    border-bottom: 1px solid #ccc;
    padding: 1rem;
}
#factor_input{
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.calculatorhead{
    font-size: 1.5rem;
    font-weight: 600;
}
.contentchamberofcalculator{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.lacandtile{
    display: flex;
    flex-direction: column;
    gap : 0.2rem ;
    font-size: 0.9rem;
}
.optionlac{
    width: 100%;
    flex: 1;
}
.calculate_factors{
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}
.calculator_info{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.infocal{
   padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   gap  : 4rem ;
   flex :5 ;
}

.calculator{
    flex: 3 ;
}
.locationfactor{
    font-weight: 600;
    font-size: 1.1rem;
}
.infocalsec{
  display: flex;
  flex-direction: column;
  gap : 1rem ;
    font-size: 1rem;
    flex: 2;
}
.calbutton{
    color: white;
    border-radius: 8px; 
    background-color: #09a7b3; 
    padding: 10px 12px;
    cursor: pointer;
    display: flex ;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.output_field_result{
    border: 1px solid #ccc;
    border-radius: 10px ;
    padding: 1rem;
}
.resultcal,
.rateoutput{
    font-size: 0.9rem;
    font-weight: 600;
}
.calmatrix{
    font-weight: 600;
    font-size: 1.2rem;  
}
.locationfactordetails{
    font-weight: 600;
    font-size: 1rem;
}
@media (max-width: 768px) { 
    .fullcal{
        border: 1px solid #ccc;
        border-radius: 10px ;
       
    } 
    .calculatorhead{
        font-size: 1rem;
        font-weight: 600;
    }
    .infocal{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
       gap  : 2rem ;
       padding: 1rem;
    }
 
    
    .calculate_factors{
    font-size: 0.8rem;
    }
}




//
.popup-overlay {
    position: fixed;
    top: 0;
  
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 2px;
    right: 10px;
    border: none;
    background: transparent;
 
    cursor: pointer;
  }
  .close_cal_info{
    width: 20px;
    height: 20px;
  }
  .calculator_content{
    padding: 2rem;
  }