.blog_section{
    display: flex;
    flex-direction: column;
    gap : 1.5rem ;
    padding: 4rem;
    font-family: "Inter", sans-serif;
}
.blog_slider_container{
    display: flex;
   // overflow: hidden;
    justify-content: space-evenly;
 
   
}
.controller_blogs{
    display: flex;
    gap : 1rem ;
  
    
}
.next_button_blog,
.prev_button_blog {
  width: 60px; /* Adjust the size as needed */
  height: 60px; /* Adjust the size as needed */
  border-radius: 50%;
  background-color:#09a7b3;; /* Background color for the button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none; /* Remove default border */
  outline: none; /* Remove default outline */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */
  transition: background-color 0.3s ease; /* Optional: transition effect on hover */
}

.next_button_blog:hover,
.prev_button_blog:hover {
  background-color:#09a7b3;; /* Hover effect color */
}

.next_button_blog img,
.prev_button_blog img {
  width: 16px; /* Size of the arrow icon */
  height: 16px; /* Size of the arrow icon */
  object-fit: contain;
}

.blog_slider_section{
    display: flex  ;
  //overflow: hidden;
    gap : 2rem ;  
  
}
.blog_card_img{
    width: 20.5vw;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 30vh;
}


.card_blog_container{
    display: flex;
    border-radius: 10px ;
    border: 1px solid rgb(201, 201, 201);
    flex-direction: column;
    gap : 0.5rem ;
}
.card_content_headline{
    display: flex;
    flex-direction: column;
 gap : 1rem ;

padding : 1rem;
}

.card_headlin_blog{
    font-size: 1.3rem;
    height: 5rem ;
    font-weight:  600;
}
.card_blog_desc{
    height: 6rem ;
}
.blog_container_headline{
    font-size: 3rem;
    font-weight: 600;
    
}
.desc_what_are_about{
    font-size: 1rem;
    
    color: #728197;
    
} 


.prev_button_blog , .next_button_blog{

    cursor: pointer;
    border: 1px solid rgb(201, 201, 201);
    
}
span {
    color: #09a7b3;
}
.gotoblog_page{
    flex : 1 ;
    cursor: pointer;
    color: white;
    display: flex;
    width: 10vw;
    padding: 0.5rem;
    text-align: center;  
    justify-content: center;
    background: #09a7b3;;
    border-radius: 5px;
    box-shadow: 0 4px 6px #8a8d8d, 0 1px 3px #09A7B3;
    /* Transition for smooth animation */
    transition: box-shadow 0.4s, transform 0.4s;
}
.gotoblog_page:hover {
    /* Adjust box-shadow on hover */
    transform: scale(1.05);
  }

.blog_read_article_link{

}
.blog_read_article_link_img{
    display: flex;
    flex-direction: row ;
    gap : 0.3rem ;
    font-size: 1.1rem;
    font-family: "Poppins",sans-serif;
    font-weight: 500;
}

.upleftarrow{
width: 16px;
height: 16px;
}

@media (max-width: 768px) and (min-width: 320px) { 
.blog_slider_section{
    display: flex  ;

    flex-direction: column;
}
.blog_card_img{
    width: 100%;
    height: 30vh;
}
.card_blog_container{ 
   
}
.gotoblog_page{
    width: 25vw;
   text-align: center;
   justify-content: center;
}
.blog_container_headline{
    font-size: 2rem;
    
}
.blog_section{
    display: flex;
    flex-direction: column;
    gap : 1.5rem ;
    padding: 1rem;
    font-family: "Inter", sans-serif;
}
.card_headlin_blog{
    font-size: 1.3rem;
    height: auto ;
    font-weight:  600;
}
.card_blog_desc{
    height: auto ;
}

}


  