@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
body {
  	margin: 0; line-height: normal;
}
:root {
  	
  	/* fonts */
  	--title-large: Inter;
  	
  	/* font sizes */
  	--title-large-size: 22px;
  	--body-large-size: 16px;
  	--label-small-size: 12px;
  	
  	/* Colors */
  	--text-primary: #1a212b;
  	--text-secondary: #728197;
  	--background-secondary: #f6f8fb;
  	--data-visualization-soft-indigo: #c0d3f9;
  	--data-visualization-soft-blue: #bcf2ff;
  	--data-visualization-soft-orange: #ffd4bc;
  	--data-visualization-soft-pink: #f8b0da;
  	--data-visualization-soft-green: #dbf1bc;
  	--data-visualization-soft-grey: #dee4ed;
  	--interactive-primary-solid-enabled-foreground: #fff;
  	--data-visualization-soft-violet: #dabeea;
  	--data-visualization-soft-yellow: #fff4b2;
  	--data-visualization-soft-red: #ffbdcb;
  	--interactive-primary-solid-enabled-background: #09a7b3;
  	
  	/* Gaps */
  	--gap-5xl: 24px;
  	--gap-6xs-2: 6.2px;
  	--gap-5xs: 8px;
  	--gap-6xs-6: 6.6px;
  	
  	/* Paddings */
  	--padding-smi-3: 12.3px;
  	--padding-lg-4: 18.4px;
  	--padding-sm-1: 13.1px;
  	--padding-lgi-7: 19.7px;
  	
  	/* Border radiuses */
  	--br-xl: 20px;
  	--br-5xs: 8px;
  	--br-smi-3: 12.3px;
  	--br-base: 16px;
  	--br-sm-1: 13.1px;
  	
}