.sdphead{
    font-family: "Inter" ,sans-serif ;
    display: flex;
    flex-direction: column;
    gap : 1rem  ;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 1.5rem;
     }
.sdptext {
    display: flex;
    color: #1a212b;
    justify-content: space-between;
}

.sdpnameWrapper {
      display: flex;
      text-align: left;
      flex-direction: column;
      gap : 0.3rem ;
   }
   .sdpbordeauxGetaway {
    align-self: stretch;
    font-size: 1.5rem;
    color: #1a212b;
    font-weight: 600;
}
.sdplocation {
    display: flex;
    gap : 0.3rem ;
}
.sdpmappinIcon {
    width: 15px;
    height: 15px;
}
.sdpbroadwayWest1 {
    display: flex;
   
    font-size: 0.8rem;
    color: #728197 ;
    align-items: center;
}
.sdptitle {
    display: flex;
    flex-direction: column;
   
}
.sdpshelvesInA1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a212b; 
  }
  .store_area_insdp{   
  font-size: 0.8rem;
  color: #728197 ;
  text-align: right;
  }

  
  // section2
  .sdpimage{
    display: flex;
    gap : 0.5rem ;
   
}
.sdpimg1 {
    border-radius: 8px;
    height: 60vh;
   width: 55vw; 
}
.sdpimgsec{
    display: flex;
    flex-direction: column ;
    gap : 0.5rem ; 
}
.sdpimg2{
    border-radius: 8px ;
    width: 33vw;
    height:29vh ;
    border-bottom-left-radius: 0px;
}
.sdpimg3{
    border-radius: 8px ;
    border-top-left-radius: 0px;
    width: 33vw;
    height:29.5vh ;
}

//section 3

.sdpbreadcrumbToDetach { 
    display: flex;
    flex-direction: row;
    color: #09a7b3;
    
}
.sdplinkcity {
     text-decoration: none;
 }
 .sdpRightarrowIcon{
     width: 3vw;
    //margin-top:0.6rem;
     height: 3vh;
   
 }

 .sdpicon {
     display: flex;
     flex-direction: row;

 }
 .sdplink2 {
   
    color: #1a212b;
 }
 .sdplinkhome{ 
    display: flex; 
    gap  : 0.1rem ;
     color: #09a7b3;
      }

// responsive designs 









@media (max-width: 768px) and (min-width: 320px) {
    .sdphead{
        font-family: "Inter" ,sans-serif ;
         display: flex;
         flex-direction: column;
         padding: 0rem;
         gap : 0rem ;
        
       
         }
      
    .sdptext {
        padding: 1.5rem;
        padding-top: 1rem;
        
    }
    .sdpnameWrapper {
         
       }
       .sdpname {
        display: flex;
      
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        
    }  .sdpbordeauxGetaway {
        align-self: stretch;
        font-size: 1.2rem ;
        font-family: "Inter" ,sans-serif ;
        font-weight: 600;
    }
    .sdplocation {
        display: flex;
      
    }
    .sdpmappinIcon {
    
        width: 20px;
        height: 20px;
        
    }
    .slick-next{
        right: 0px;
    }
    .slick-next:before{
        display: none;
    }
  
    .sdptitle {
        display: flex;
        flex-direction: column;
       
    }
    .sdpshelvesInA1 {
      display: none;
      }
      .store_area_insdp{   
       width: 100px;
      color: #728197 ;
     text-align: right;
      }
    
      // section2
      .sdpimage{
        display: flex;
        flex-direction: column;
        gap : 10px  ; 
    }
    .sdpimg1 {
        border-radius: 0px;
         height: 40vh;
         width: 100%; /* Set maximum width to 60% of the viewport height */
    }
    .sdpimgsec{
        display: flex;
       
        gap : 10px  ; 
    }
    .sdpimg2{
        border-radius: 0px ;
        height: 40vh;
         width: 100%;
    }
    .sdpimg3{
        border-radius: 0px ;
        height: 40vh;
         width: 100%;
    }
    
    //section 3
    
    .sdpbreadcrumbToDetach {
        align-items: center;

        padding: 1.5rem;
      
    }
    .sdplinkcity {
        width: 120px; /* Adjust this width based on your layout */
  white-space: nowrap; /* Ensure the text stays in one line */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Append "..." to the end of the overflowing text */
         text-decoration: none;
        
         font-size: 0.8rem;
     }
     .sdpRightarrowIcon{
        width: 3vw;
     
        height: 3vh;
      
    }
     .sdpicon {
         display: flex;
         flex-direction: row;
         align-items: flex-start;
         justify-content: flex-start;
     }
     .sdplink2 {
        font-size: 0.8rem;
      
         color: #1a212b;
     }
     .sdplinkhome{  
         color: #09a7b3;
          }
          .sdp_home_path{
            font-size: 0.8rem;
          }

}