.questions-faq-custom{
    font-family: 'Poppins' , sans-serif ;
    margin-bottom : 2em;
}

.mainone{
    font-size: 20px ;
    display: flex ;
    align-items: center;
    padding: 10px ;
    height: 60px ;
}


.questionfaq{
    width : 80%;
    color: #1a212b;
    font-family: 'Inter', sans-serif;
    margin: 1em;
}

.answers{
    margin-bottom : 8px ;
    font-family: 'Inter', sans-serif;
    margin-left: 3em;
    width : 80%;
    color :#728197; 
   
}
.plus{
    cursor: pointer;
}