.privacy_page{
    padding: 8rem ;
    padding-top: 5rem;
    display: flex;
    gap : 2rem ;
   flex-direction: column ; 
   font-family: "Inter",sans-serif;
}
.privacy_adress_info{
    text-align: right;
}
.privacy_head_img{
    text-align: center;
}
.privacy_title{
   text-align: center;
   font-size: 30px;
   margin-bottom: 5rem ;
   font-weight: 600;
}
.privacy_content_headlines{
    font-size: 22px;

    font-weight: 600;
}
.privacy_content{
    margin-bottom: 3rem;
}
.privacy_content_subheadlines{
    font-size: 17px;
    margin-bottom: 1rem ;
    font-weight: 600;
}
.company_name{
    font-size: 17px;
    font-weight: 600;
}
.privacy_bottom_line{
    color: gray;

}
.italic_privacy{
    font-style: italic;
}
 .bold{
    font-weight: 600;

 }



 @media (max-width: 768px) and (min-width: 320px) {
 .privacy_page{
    padding: 1rem ;
    padding-top: 3rem;
    display: flex;
    gap : 1rem ;
   flex-direction: column ; 
   font-family: "Inter",sans-serif;
}
.privacy_title{
    text-align: center;
    font-size: 24px;
    margin-bottom: 3rem ;
    font-weight: 600;
 }
 .privacy_content_headlines{
     font-size: 15px;
 
     font-weight: 600;
 }
 .privacy_content_subheadlines{
    font-size: 13px;
    margin-bottom: 1rem ;
    font-weight: 600;
}
.company_name{
    font-size: 17px;
    font-weight: 600;
}
}