.slider_container{
    margin-bottom: 3rem;
 padding: 6rem ;
 gap : 2rem ;
 display: flex;
   font-family: "Inter",sans-serif;
}
.slider_section {
    display: flex;
    flex-direction: column;
    gap: 2rem; /* Gap between the grid items */
}




.slider_section_1{
    display: flex;
    flex-direction: row;
    gap  : 2rem ;
    flex : 5;
}
.slider_section_2{
    display: flex;
    flex-direction: row;
    gap  : 2rem ;
    flex : 5;
}

.slide_1_help_brand,.slide_4_help_brand{
    flex : 2 ;
    border: 1px solid  #cbd4e1 ;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap : 1rem ;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;

}

.slide_2_help_brand, .slide_3_help_brand{
    flex: 3;
    border: 1px solid  #cbd4e1 ;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap : 1rem ;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;

}




.slide_1_help_brand:hover,
.slide_2_help_brand:hover,
.slide_3_help_brand:hover,
.slide_4_help_brand:hover{
    transform: scale(1.05);
    border: 1px solid #09a7b3;;
}
.slide_1_img,
.slide_2_img,
.slide_3_img,
.slide_4_img{
width: 50px;
height: 50px;
}
.desc_slide_help_brand{
    font-size: 0.9rem;
    color: #1A212B;
text-align: center;
font-weight: 400;

}

.slider_img_how_help_brand{
    width: 500px;
    height: 500px;
}
.headline_slide_help_brand{
color: #1A212B;
font-weight: 600;
font-size: 1.5rem;
}
@media (max-width: 768px) and (min-width: 320px) {
    .headline_slide_help_brand{
        color: #1A212B;
        font-weight: 600;
        font-size: 1.2rem;
        }
        .desc_slide_help_brand{
            font-size: 0.7rem;
            color: #1A212B;
        text-align: center;
        font-weight: 400;
        
        }
    .slider_img_how_help_brand{
        width: 85vw;
        height: 100%;
    }
    .slider_container{
        margin-bottom: 3rem;
     padding: 2rem ;
     display: flex;
     flex-direction: column;
      
    }
 
    .slider_section{
        display: flex;
        flex-direction: column;
        gap : 2rem ;
    }
    .slider_section_1{
        display: flex;
        flex-direction: column;
        gap  : 2rem ;
        flex : 5;
    }
    .slider_section_2{
        display: flex;
      flex-direction: column;
        gap  : 2rem ;
        flex : 5;
    }
    
}





/* Infinite sliding effect 
@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.slider_section_1 {
  animation: slideLeft 15s linear infinite;
}

.slider_section_2 {
  animation: slideRight 15s linear infinite;
}
*/