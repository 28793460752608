.brand-container {
    font-family:  'Inter', sans-serif;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
    

}

.brand-container > div{
    box-sizing: border-box;
}

.brand-gap-container{
    align-items: center;
    justify-content: center;
  
}

    .brand-gap-subtitle{
   
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Creates 3 columns of equal width */
   
        justify-content: flex-start;
        align-items: flex-start;
    gap:5em;
}

.borderof_brand{
    border-radius: 15px ;
   font-size: 15px;
   font-weight: 500;
    width : 35vh ;
    height: 30vh;
    border  : 1px solid #e0e0e0;

    transition: all 0.6s ease-in-out;
   
    padding: 12%;
}


  .borderof_brand:hover{
   transform: scale(1.09); 
    box-shadow: 0 0 10px rgba(71, 153, 186, 0.5);
    border :none ;
  }


.brand_img_section{
    width: 40px; 
    height: 40px 
}


.brand_Data_all{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}



.brand_desc{
 color :  #728197;
}

.brand_headandicon{
    display: flex;
    flex-direction: column;
     font-size: 22px;
     gap : 0.7rem ;
    font-weight: 700 ;
}

.brand-gap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
}

.brand-gap-title p{
    margin: 0;
}

.brand-gap-title {
 display: flex;
    gap: 1rem;
    width: 80vh ;   
    font-size: 3rem;
    font-weight: 600;
    margin: 0.5em 0 1em 0;
}

.brand-gap-highlight{
    color: #728197;
    width: 90vh;
    text-transform: uppercase;
    letter-spacing: .12em;
}
.brand_works_color{
    color: #09a7b3;
}

@media (max-width: 800px) {
    .brand-container {
        flex-direction: column;
        margin-left: 1.2rem ;
        justify-content: space-evenly;
        margin: 1em 0;
        gap: 1.5em;
    }
}

@media (max-width: 768px) and (min-width: 320px) {
  
    .brand-gap-subtitle{
     
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Creates 3 columns of equal width */
    justify-content: center;
    align-items:center;
    gap:2rem;
} 
.borderof_brand{
    border-radius: 15px ;
   font-size: 15px;
   font-weight: 500;
    width : 60vw !important ;
    height: 20vh;
    border  : 1px solid #e0e0e0;
  
    padding: 12%;
}


.borderof_brand:hover{
    transform: scale(1.03); 
     box-shadow: 0 0 10px rgba(71, 153, 186, 0.5);
     border :none ;
   }
 

   .brand-gap-title {

       display: flex ;
       gap: 0.6rem ;
       width : 50vw   ;
       font-size:1.5rem ;
       font-weight: 600 ;
       margin: 0.5em 0 1em 0 ;
   }
   
   .brand-gap-highlight{
   
    color: #728197;
    width: 60vw;
    font-size: 0.6rem ;
    text-transform: uppercase;
    letter-spacing: .02em;
}


}