.cookie-consent{
    font-family: "Inter",sans-serif;
     //border: 1px solid grey ;
     padding: 1rem ;
     display: flex;
     flex-direction: column;
     gap : 0.6rem ; 
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
     width: 35%;
     border-radius: 20px;
    //justify-content: right;
    position: fixed; /* Stay in place */
    top: 84%;
    left: 79%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Sit on top */

}
.cookie_summary{
    font-size: 0.8rem;
}
.cookie_consent_headline{
    color: black;
   // font-weight: 600; 
 font-weight: bolder;
    font-size:1rem ;
}
.cookie_consent_button{
display: flex;
justify-content: right;
gap : 1rem ;
}
.cookie_accept_button{
  
    background-color: #09a7b3;
    color: white;
    border-radius: 10px;
    padding: 0.5rem;
    cursor: pointer;
  
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
    width: 15%;
}

.cookie_decline_button{
    background-color: #ffffff;
    color: #09a7b3;
    border:  1px solid #09a7b3;
    padding: 0.5rem;
    width: 15%;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    font-size: 0.7rem;
    font-weight: 600;
}
// responsive
@media (max-width: 768px) and (min-width: 320px) {
    .cookie-consent{
        
         width: 60%;
         border-radius: 10px;
        top: 85%;
        left: 60%;
        transform: translate(-50%, -50%);
        z-index: 1000; /* Sit on top */
    
    }
    .cookie_summary{
        font-size: 0.6rem;
    }
    .cookie_consent_headline{
        font-size:0.9rem ;
    }
    .cookie_accept_button{
        border-radius: 8px;
        padding: 0.4rem;
        font-size: 0.5rem;
        font-weight: 500;
        width: 12%;
    }
    
    .cookie_decline_button{
        padding: 0.4rem;
        width: 12%;
        border-radius: 8px;
        font-size: 0.5rem;
        font-weight: 500;
    }

}