.statisticsstatsParent{
   padding-top: 3rem ;
   padding-bottom: 3rem;
  font-family: "Inter" ,sans-serif;
   background-color: #09A7B3;
}
.statistics_headlines{
   display: flex;
   justify-content: space-evenly;
align-items: center;

   padding: 2rem;
  
}
.stats_desc{
   font-size: 1.2rem;
   color: white;
}
.statistics_main{
color: white;
font-weight: 600;
font-size: 2rem;
}
.stats_data {
   font-weight: 500;
   color: rgb(255, 255, 255); font-size: 3rem;
   
   // Target the span inside CountUp component
   span {
      font-size: 3rem;
      font-weight: 500;
       color: rgb(255, 255, 255); // Set the color to black for the span inside .stats_data
   }
}
@media (max-width: 768px) and (min-width: 320px) {
   .statisticsstatsParent{
      padding-top: 2rem ;
      padding-bottom: 2rem;
     font-family: "Inter" ,sans-serif;
      background-color: #09A7B3;
   }
   .stats_desc{
      font-size: 0.5rem;
      color: white;
   }
   .statistics_main{
   color: white;
   
   font-size: 1rem;
   }
   .stats_data {
      font-weight: 500;
      color: rgb(255, 255, 255); font-size: 1.2rem;
      
      // Target the span inside CountUp component
      span {
         font-size: 1.2rem;
         font-weight: 500;
          color: rgb(255, 255, 255); // Set the color to black for the span inside .stats_data
      }
   }
   .statistics_headlines{
      display: flex;
      gap : 1rem ;
   
      padding: 1rem;
     
   }


}