.listing_store_dashboard{
    display: flex;
    font-family: "Inter",sans-serif;
   
}
.store_dashboard_menu{
    display: flex;
    flex-direction: column;
    height: 100vh ;
    justify-content: space-between;
}
.top_dashboard_sidebar, .bottom_dashboard_sidebar{
    padding: 1rem;
    display: flex;
  
    flex-direction: column;
    gap : 1rem ;
}
.header_logo_listing{
    padding: -1rem !important;
}
.listing_sidebar_dashboard{
flex: 15;
height: 100vh;
border-right: 1px solid #CBD4E1;
}
.listing_mainboard{
flex: 85 ;
height: 100vh;
}

.main_board_section{
 //   display: flex;
   background: #F6F8FB;
 //   align-items: center;
  //  justify-content: center;
  }
  

.getting_started, 
.dashboard_listing, 
.edit_store_listing, 
.marketplace_listing, 
.super_analystics_listing, 
.logout_dashboard, 
.edit_store, 
.sharefeedback_listing, 
.invite_people_listing {
    display: flex;
    gap: 0.5rem;
    font-size: 0.7rem;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ; /* Smooth transition effect */
    padding: 0.1rem; /* Add consistent padding */
    border-radius: 5px;
}

.getting_started:hover, 
.dashboard_listing:hover, 
.edit_store_listing:hover, 
.marketplace_listing:hover, 
.super_analystics_listing:hover, 
.logout_dashboard:hover, 
.edit_store:hover, 
.sharefeedback_listing:hover, 
.invite_people_listing:hover {
    background-color: #09A7B3; /* Change to your desired hover background color */
    border-radius: 5px;
    padding: 0.1rem;
}

.logo_sidebar{
    width: 1rem;
    height: 1rem;
} 
.store_name_logo{
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 0.5rem ;
}
.store_logo .logo_sidebar {
    width: 1.5rem;
    height: 1.5rem;
}

.store_name_index{
    border: 1px solid #CBD4E1;
    width: 10vw;
    font-size: 0.7rem;
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center; 
    height: 1.5rem ;
    border-radius: 5px;
}
.listing_mainboard{
    display: flex;
    flex-direction: column; 
}
.top_bar_listing_mainboard{
    background: white;
    display: flex;
    border-bottom: 1px solid #CBD4EA ;
     flex: 1.1;
}
.main_board_section{
  flex: 8.9;
}
.account_create_form, .store_form, .shelf_form, .bank_form{
    display: flex;
    flex-direction: row;
   // gap: 1rem;
    align-items: center;
    justify-content: space-between;
   // text-align: center;
   // justify-content: center;
}
.forms_category{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 1rem ;
    justify-content:flex-end;
}
.form_section_topbar{
    display: flex;
    justify-content: space-between;
}
.form_section_headline{
    font-weight: 500;
}
.No_of_filled_forms{
    font-size: 0.7rem;
    color: #7c8288;

}
.getting_start_registration{
   // margin-top: 6vh;
    display: flex;
     padding: 2rem;
    padding-top: 3rem;
    flex-direction: column;
    justify-self: center;
    align-items: center;
}
.form_containers{
    display: flex;
    flex-direction: column;
    gap : 1rem ;
}
.forms_sections{
    display: flex;
    flex-direction: column;
    gap : 2rem ;
    padding: 1rem;
    border : 1px solid #CBD4E1;
    background-color: white;
    width: 45vw;
    border-radius: 5px;
}
.main_form_board{
    display: flex;
    flex-direction: column ;
    gap: 1rem;
}

.form_subheadline{
    font-size: 0.7rem;
    }
    .form_headline{
        font-size: 0.9rem;
        font-weight: 500;
    }
    .form_headline_main{
        font-size: 1.1rem;
        font-weight: 600;
        color: #001325;
    
    }
    .form_container_subheadline{
        font-size: 0.7rem;
        text-align: center;
        width: 40vw;
        color: #7c8288;
    }
 .form_container_headline{
    display: flex;
    flex-direction: column;
   align-items: center;
   gap: 0.5rem ;
 }

.form_status{
background-color: #09A7B3;
padding: 0.4rem;
cursor: pointer;
color: white;
border-radius: 5px;
font-size: 0.8rem;

}
.form_status_filled_icon{
   
     
}

.header_dashboard{
display: flex;
padding: 1rem;
align-items: center;
//justify-content: center;
flex-direction: row;
gap: 1rem ;
}
.header_section{
    font-weight: 600;
    font-size: 1.1rem;
}
// invite scss
.invite_retailers{
    display: flex;

    justify-content: center;
    align-items: center;
    height: 80vh;
}
.invite_reatail_container{
    display: flex;
    background: #ffffff;
     min-width: 50vw ;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to the table */
    flex-direction: column;
    padding: 2rem ;
    gap : 2rem ;
    border-radius: 10px;
}
.table_invites_list_container{
    display: flex ;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0.5rem;
    padding-left:  1rem ;
    padding-right: 1rem ;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #ffffff ;
    justify-content: space-between;
  }

  // profile 
  .edit_section_retailer{
    display: flex;
    gap : 2rem ;
   // padding: 1rem;
    padding-left: 2rem;
    font-size: 0.9rem;
  }
  .profile_dashboard{
    display: flex;
    flex-direction: column;
  }

.toggle-image{
    display: none;
}
  @media (max-width: 768px) and (min-width: 320px) {
  // responsive design

  .form_containers{
    display: flex;
    flex-direction: column;
    gap : 1rem ;
}
.forms_sections{
    display: flex;
    flex-direction: column;
    gap : 2rem ;
    padding: 1rem;
    border : 1px solid #CBD4E1;
    background-color: white;
  //  width: 45vw;
    border-radius: 5px;
}
.getting_start_registration{
    // margin-top: 6vh;
     display: flex;
      padding: 1rem;
     padding-top: 3rem;
     flex-direction: column;
     justify-self: center;
     align-items: center;
 }

    .forms_sections{
        font-size: 0.8rem;
        display: flex;
        flex-direction: column;
        gap : 2rem ;
        padding: 1rem;
        border : 1px solid #CBD4E1;
        background-color: white;
       width: 90%;
        border-radius: 5px;
    }
    .form_headline{
        font-size: 0.8rem;
        font-weight: 500;
    }
    .form_container_subheadline{
        font-size: 0.7rem;
        text-align: center;
     width: 90%;
        color: #7c8288;
    }

    .form_subheadline{
        font-size: 0.6rem;
        width: 155px;
        }
        .form_status{
            background-color: #09A7B3;
            padding: 0.4rem;
            cursor: pointer;
            color: white;
            border-radius: 5px;
            font-size: 0.7rem;
            
            }
            .toggle-image{
                width: 2em;
                display: block;
                cursor: pointer;
                height: 2em;
            }
            .listing_sidebar_dashboard {
                position: fixed;
                top: 0;
                z-index: 99;
                right: 0;
                width: 150px;
                height: 100%;
                background-color: #f4f4f4;
                box-shadow: -2px 0 5px rgba(0,0,0,0.5);
                padding: 20px;
                transition: transform 0.3s ease;
                transform: translateY(0);
              }
              
              /* Hide the sidebar when not open */
              .listing_sidebar_dashboard.hidden {
                transform: translateX(100%);
              }
              .store_name_index{
                width: 8rem;
              }

              // invitation
              .invite_email_input_ecomm_send_invite {
                flex: 2;
                min-width: 5rem;
                background-color: #09a7b3;
                padding: 0.5rem ;
                text-align: center;
                color: white;
                cursor: pointer;
                border-radius: 5px;
                font-size: 0.8rem;
              }
                .invite_retailers{
                    padding: 1rem;
                    height: 60vh;
                }
                .invite_email_input_ecomm{
                
                    gap: 1rem ;
                    flex: 10;
                  }
          
            
}