
header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    /* gap: 6em; */
    justify-content: space-between;
    align-items: center;
    font-family: Inter, sans-serif;
    font-weight: 500;
    padding: 1em;
    border-bottom: 1px solid #cbd4e1;
    /* max-width: 1200px; */
}
  
.logo-icon{
    filter: drop-shadow(3px 5px 5px rgb(0 0 0 / 0.4));
}


.nav-item {
    text-align: center;
}

.nav-sub-item{
    text-decoration: none;
    background: linear-gradient(#09a7b3, #09a7b3) bottom / 0 .1em no-repeat;
    transition: 0.8s background-size;
    color: gray;
}

.btn{
    text-decoration: none;
    border: 1px solid #09a7b3;
    padding: 0.5em 1em;
    border-radius: 5px;
}

.centre .nav-sub-item:hover{
    background-size: 100% .1em;
}

.btn-light{
    background-color: white;
    color: #09a7b3;
}

.btn-dark{
    background-color: #09a7b3;
    color: white;
}


.centre {
    display: grid;
}

.centre{
    grid-template-columns: repeat(3, 1fr);
    /* gap: 1em; */
    justify-content: space-evenly;
}

.right{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    /* background-color: green; */
}
.burger_menu_navbar{
    width: 20px;
    height: 20px;
}
.logged-in-user-name{
    display: flex;
    align-items: center;
        width: 100%;
     padding-left: 0.6rem;
        justify-content: space-between;
    
   

}
.user-profile-menu{
     grid-column: 2/-1;
    border : 1px solid rgb(196, 192, 192) ;
     padding: 0.5rem ;
    border-radius:50px;
   transition: box-shadow 0.3s ease-in-out ; 
    /* max-width: fit-content; */
}

    .user-profile-menu:hover {
        box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.2); /* Darker shadow */
    }



.logged-in-profile{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
    cursor: pointer;
    /* border: 2px solid #000; */
}


.user-icon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  
  
 
}

.user-menu-dropdown {
    position: absolute;
    top: 11% !important;
    right: 1% !important;
    z-index: 5;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    border-radius: 10px;
    font-size: 0.8rem ;
    text-align: left;
    padding: 1rem;
    font-weight: 500;
    padding-right: 4rem;
    transform-origin: top center;
    animation: rollDown 0.5s ease-in-out;
    box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.2); /* Darker shadow */
}

@keyframes rollDown {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}

.user-menu-items{
    display: flex;
    flex-direction: column;

}

.user-menu-item {
    display: flex;
    align-items: center;
    gap: 1.5em;
    padding: 0.3rem; /* Consistent padding */
    border-radius: 5px; /* Consistent border-radius */
    box-sizing: border-box; /* Ensures padding/border don’t affect size */
    transition: background-color 0.3s ease-in-out; /* Smooth color transition */
}

.user-menu-item:hover {
    background-color: #e9e9e9;
    width: 120%;
    cursor: pointer; /* Pointer on hover */
    /* Padding and border-radius remain the same to prevent shifts */
}


.user-menu-item-text{
    text-decoration: none;
    color: #000;
}



/* Mobile View */

.ham-icon{
    filter: drop-shadow(3px 5px 5px rgb(0 0 0 / 0.4));
    width: 30px;
}

.ham-dropdown{
    display: flex;
    justify-content: flex-end;
}

.guest-menu-dropdown{
   text-align: left;
    position: absolute;
    right: 1%;
    z-index: 5;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    border-radius: 10px;
    padding: 0.8rem;
    transform-origin: top center;
    animation: rollDown 0.5s ease-out;
    box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.2); /* Darker shadow */
}

.guest-menu-items{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.guest-nav-item{
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 1em;
    padding-right: 1.5rem;
}

.guest-nav-sub-item{
    text-decoration: none;
    color: #000;
}


@media (min-width: 1200px){
    header{
        font-size: 1rem;
        /* gap: 10em; */
    }
}

@media (max-width: 1023px){
    header{
        font-size: 0.8rem;
        /* gap: 4em; */
    }

    .right{
        gap: 1em;
    }
    
}

@media (max-width: 768px) {
    header {
        /* grid-template-columns: 1fr 1fr ;
        grid-template-areas: 
        "logo centre"
        ". buttons"; */
        gap: 0;
    }

    .centre, .right {
        /* grid-template-columns: 1fr; */
        /* grid-area: centre; */
    }

    .centre {
        /* grid-area: centre; */
    }

    .centre .nav-sub-item{
        padding: 1em ;
        font-size: 0.5rem;
    }

    .right {
        /* grid-area: buttons; */
        font-size: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .logged-in-profile{
        font-size: 0.5rem;
    }

    .burger_menu_navbar{
        width: 15px;
        height: 15px;
    }

    .user-icon{
        
        width: 20px;
        height: 20px;
        margin-bottom: 0.15rem;
    }

    .logo {
        /* grid-area: logo; */
        grid-row-start: 1;
        display: flex;
        justify-content: flex-start;
    }
}

@media (max-width: 495px){
    header{
        grid-template-columns: 1fr 1fr ;
        grid-template-areas: 
        "logo buttons";
    }

    .logo{
        grid-column: 1 / 2;
        align-self: flex-start;
    }
    .logged-in-user-name{
        display: flex;
    
         width: 100%;

        padding-left: 0.5rem;

        align-items: center;
        justify-content: space-between;
          gap : 0.4rem;   
       
    
    }

    .user-menu-dropdown {
        position: absolute;
        padding-right: 2rem;
        top: 8% !important;
        right: 1% !important;
       
    }
    .logintext{
text-align: left;
    }

    .user-profile-menu{

        grid-column: 2/ -1;
        
        border-radius: 40px ;
       
        padding: 0.3rem;
    }
 
    
    .justify-right{
        display: flex;
        justify-content: flex-end;
    }

    .logged-in-profile{
        justify-content: space-between;
    }

    .justify-center{
      text-align: left;
        display: flex;
    
    }
    .user-menu-items{
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
    
    }
}