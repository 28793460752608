.sdpTimingshopTimingsPer1 {
    position: relative;
    line-height: 24px;
    color: #728197; 
    display: flex;
    align-items: center;
    width: 600px;
    font-weight: 500;
    font-size: 1.2rem;
    flex-shrink: 0;
}
.sdpTimingmonday {
    position: absolute;
    top: 22px;
    left: 0px;
    font-size: 12px;
    line-height: 18px;
    color: #728197;
    display: flex;
    align-items: center;
    width: 94px;
}
.sdpTimingam600PmParent {
    position: absolute;
    top: 4px;
    left: 60px;
    width: 154px;
    height: 40px;
}
.sdpTiminggroupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 48px;
    height: 48px;
    object-fit: cover;
}
.sdpTimingm {
    position: absolute;
    top: 15px;
    left: 17px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 14px;
}
.sdpTiminggroupDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 48px;
    height: 48px;
}
.sdpTiminggroupParent {
    position: relative;
    width: 214px;
    height: 48px;
}
.sdpTimingt {
    position: absolute;
    top: 15px;
    left: 17px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
}
.sdpTiminggroupParent2 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 48px;
    height: 48px;
    text-align: center;
}
.sdpTimingclosed {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 154px;
}
.sdpTimingsaturday {
    position: absolute;
    top: 22px;
    left: 0px;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    width: 94px;
}
.sdpTiminggroupParent9 {
    position: relative;
    width: 214px;
    height: 48px;
    color:#b6c2d3;
}
.sdpTimingshopTimingsPerDayParent {
    width: 850px ;
    position: relative;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #cbd4e1;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px ;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 24px;
    gap: 40px;
    text-align: left;
    font-size: 16px;
    color:#1a212b;
    font-family: "Inter" ,sans-serif ;
}


// store data sdp 


.businessinfo{
    display: flex;
    flex-direction: column;
    gap : 32px ;
    margin :2% ;
    margin-left: -1.5%;
    
    font-family: "Inter" ,sans-serif ;
    transform: scale(0.9) ;
    font-family: "Inter",sans-serif;
    
}
.sdpbusinessdetails1{
    display: flex;

    border: 1px solid #CBD4E1;
    box-sizing: border-box;
    height : 112px ;
    width: 940px ;
    border-radius: 16px; 

}
.sdpbusinessdetails2{
    display: flex;
    border: 1px solid #CBD4E1;
    box-sizing: border-box;
    height : 112px ;
    width: 940px;
    border-radius: 16px; 

}
.sdpbox1{
    display: flex;
width: 280px;
padding: 20px 16px;
align-items: center;
gap: 16px;
align-self: stretch; 
border-right: 1px solid #CBD4E1;
}
.sdpboximgandtext{
    display:  flex ;
}
/*
color: var(--Text-Secondary, #728197);
/* Body/Large
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;   */

.sdptextdemo{
    color:  #1A212B ;
    width: 175px; 
    font-size: 18px;
    font-style: normal;
font-weight: 600;
line-height: 24px ;
margin-left: 10px ;
margin-top: 10px ;

}


.sdpdetailscat{
    font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 24px;
width  : 200px ;
color:  #728197 ;
margin-left: 10px ;

}
.sdpdetailscat_extra{
    color:  #728197 ; 
    margin-left:  -10px ;
       margin-top : -20px  ;
}
.sdptextdemo_extra{
    text-align: center;
    margin: 20px ;
    font-size: 20px;
     margin-left: -53px;
     margin-top: 30px ;
   font-weight: 700;
}


// responsive desing 




@media (max-width: 768px) and (min-width: 320px) {
    .sdpTimingshopTimingsPer1 {
        position: relative;
        line-height: 24px;
        color: #728197; 
        display: flex;
        font-weight: 500;
        font-size: 1.2rem;
        align-items: center;
         width: 60vw;
        flex-shrink: 0;
    }
    .sdpTimingshopTimingsPerDayParent {
     width: 100% ;
        border-radius: 20px;
        background-color: #fff;
        border: 1px solid #cbd4e1;
        box-sizing: border-box;
        overflow: hidden;
   
         
         
          
margin: 0;
padding: 1rem;
    
        align-items: flex-start;
        justify-content: flex-start;
      
        gap: 40px;
        text-align: left;
        font-size: 16px;
        color:#1a212b;
        font-family: "Inter" ,sans-serif ;
    }
    .sdp_timimg_align{
        display: grid;
  grid-template-columns: repeat(2, 1fr); /* This creates two equal columns */
  grid-gap: 20px; /* Optional: space between grid items */
    }
 
    .container_store_timing{
        padding: 1.5rem;
    }
    .sdpTiminggroupParent {
   
        width: 100% ;
        height: 48px;
    }
.businessinfo{
   padding: 0.5rem;
     margin: 0;
     margin-left: 0;
     margin-right: 0;
}
.sdpbusinessdetails1{
    display: flex;
    border: 1px solid #CBD4E1;
    box-sizing: border-box;
    height : 70px ;
   max-width: 95vw;
    min-width: 350px;
    border-radius: 10px; 
}
.sdpboximg{
    display: none;
 }
.sdpbusinessdetails2{
    display: flex;
    border: 1px solid #CBD4E1;
    box-sizing: border-box;
    height : 70px ;
     min-width: 350px;
    width: 95vw;
    border-radius: 10px; 

}

.sdpbox1{
    display: flex;
width: 100px;
padding: 10px 8px;
align-items: center;
gap: 8px;
align-self: stretch; 
border-right: 1px solid #CBD4E1;
}
.sdpboximgandtext{
    display:  flex ;
    flex-direction: column;
}
.sdptextdemo{
    color:  #1A212B ;
    width: 100px; 
    font-size: 11px;
    font-style: normal;
font-weight: 600;
line-height: 24px ;
margin-left: 0.01px ;
margin-top: 10px ;

}
.businessinfo{
    font-family: "Inter" ,sans-serif ;
  
}
.sdpdetailscat{
    font-size: 9px;
font-style: normal;
font-weight: 200;
line-height: 24px;
width  : 120px ;
color:  #728197 ;
margin-left: 0px ;

}
.sdpdetailscat_extra{
    color:  #728197 ; 
    margin-left:  0.000001px ;

       margin-top : 0.0001px  ;
}
.sdpdetailscat_extra{
    font-size: 9px;
    margin-top: -14px;
    margin-left: 10px ;
    width: 100px;
    text-align: left !important;
    color:  #728197 ; 
 
}
.sdptextdemo_extra{
    margin-top: 22px !important;
    width: 100px;
   margin-left: -20px !important;
    
    font-size: 12px;
    font-weight: 600;
}


}